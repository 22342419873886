exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-context-magic-mdx": () => import("./../../../src/pages/context-magic.mdx" /* webpackChunkName: "component---src-pages-context-magic-mdx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-ratelimit-p-1-mdx": () => import("./../../../src/pages/ratelimit-p1.mdx" /* webpackChunkName: "component---src-pages-ratelimit-p-1-mdx" */),
  "component---src-pages-ratelimit-p-2-mdx": () => import("./../../../src/pages/ratelimit-p2.mdx" /* webpackChunkName: "component---src-pages-ratelimit-p-2-mdx" */),
  "component---src-pages-usb-switcher-mdx": () => import("./../../../src/pages/usb-switcher.mdx" /* webpackChunkName: "component---src-pages-usb-switcher-mdx" */),
  "component---src-pages-v-8-ignition-mdx": () => import("./../../../src/pages/v8-ignition.mdx" /* webpackChunkName: "component---src-pages-v-8-ignition-mdx" */),
  "component---src-pages-v-8-jit-mdx": () => import("./../../../src/pages/v8-jit.mdx" /* webpackChunkName: "component---src-pages-v-8-jit-mdx" */),
  "component---src-pages-v-8-mdx": () => import("./../../../src/pages/v8.mdx" /* webpackChunkName: "component---src-pages-v-8-mdx" */),
  "component---src-pages-v-8-parser-mdx": () => import("./../../../src/pages/v8-parser.mdx" /* webpackChunkName: "component---src-pages-v-8-parser-mdx" */)
}

