import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Note = makeShortcode("Note");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3>{`الگوریتم Sliding Window ـ `}<Note mdxType="Note">{`پیاده سازی های مختلفی از این الگوریتم برای RateLimit وجود داره ولی ما تارگتمون پیاده سازی `}<a href="https://blog.cloudflare.com/counting-things-a-lot-of-different-things/">{`کلادفلیر`}</a>{` هست`}</Note></h3>
    <p>{`این الگوریتم میاد مشکل الگوریتم
fixed window
که توی بازه های زمانی ثابت، شمارنده رو صفر می‌کنه رو حل می‌کنه،
به این صورت که دو شمارنده داره، یکی برای
پنجره ثابت فعلی
و یکی برای قبلی. به شمارنده قبلی یک وزن میده و با شمارنده فعلی جمعش میکنه
تا یک تقریب نسبتا خوبی برای rate بدست بیاره
و در نهایت اونو با
rate
قانونمون چک می‌کنه. بیایم برای اینکه بهتر بفهمیم، مثل الگوریتم قبل اینم
با مثال
`}<a parentName="p" {...{
        "href": "#anchor-ratelimit-example"
      }}>{`بالا`}</a>{`
بازش کنیم:`}</p>
    <p><img parentName="p" {...{
        "src": "ratelimit_sliding_window_1.png",
        "alt": "sliding window 1"
      }}></img></p>
    <p>{`فرض می‌کنیم اول ۵ تا درخواست اومدن و مثل تصویر بالا توی ثانیه 8ام، آخرینشون رو شمردیم.
توی ثانیه 12ام یک درخواست جدید میاد:`}</p>
    <p><img parentName="p" {...{
        "src": "ratelimit_sliding_window_2.png",
        "alt": "sliding window 2"
      }}></img></p>
    <p>{`یک بازه به اندازه t که اینجا
10 ثانیه هست در نظر می‌گیریم و آخرش رو زمان فعلی درخواستمون می‌زاریم.
اول این بازه میوفته توی `}<inlineCode parentName="p">{`current_time - t`}</inlineCode>{` که توی این مثال میشه
ثانیه 2م.
حالا باید ببینیم که چند درصد این بازه توی
پنجره ثابت
قبلی افتاده که اینجا میشه
80 درصد در نتیجه وزنمون میشه 0.8. شرط چک کردنمون میشه:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-text"
      }}>{`rate = last_rate * weight + current_rate
rate = 5 * 0.8 + 0 = 4
`}</code></pre>
    <p>{`چون عددی که بدست اوردیم کمتر از
rate
قانونمونه، درخواست بلاک نمیشه و به شمارنده پنجره ثابت فعلی یکی اضافه می‌کنیم.
وقتی هم میریم به پنجره بعدی (مثلا بریم بعد از ثانیه 20ام)
میایم مقدار `}<inlineCode parentName="p">{`current_rate`}</inlineCode>{` رو میریزیم توی `}<inlineCode parentName="p">{`last_rate`}</inlineCode>{` و
`}<inlineCode parentName="p">{`current_rate`}</inlineCode>{` رو صفر می‌کنیم.`}</p>
    <p>{`یک نمونه پیاده سازی کامل این الگوریتم:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-rust"
      }}>{`// <hide>
use std::time::{Duration, Instant};

#[derive(Clone)]
// </hide>
struct Rule {
    time_duration: Duration,
    rate: usize,
}

// <hide>
#[derive(Debug)]
// </hide>
struct State {
    /// last time we counted a request
    pub last_count_time: Instant,
    /// last block request count
    pub last_window_count: usize,
    /// current window request count
    pub current_window_count: usize,
}

fn is_rate_limited(origin: Instant, current_time: Instant, rule: &Rule, state: &mut State) -> bool {
    let duration_since_last_req = current_time.duration_since(state.last_count_time);
    if duration_since_last_req >= rule.time_duration * 2 {
        state.last_window_count = 0;
        state.current_window_count = 0;
    } else if duration_since_last_req > rule.time_duration {
        state.last_window_count = state.current_window_count;
        state.current_window_count = 0;
    }
    let t_ms = rule.time_duration.as_millis();
    let current_window_start = {
        let normal = current_time.duration_since(origin).as_millis() / t_ms;
        origin + Duration::from_millis((normal * t_ms) as _)
    };
    let w = {
        let d = current_time - current_window_start;
        let dp = if d > rule.time_duration {
            Duration::ZERO
        } else {
            rule.time_duration - d
        };
        (dp.as_millis() as f64) / t_ms as f64
    };

    let count = ((w * state.last_window_count as f64) as usize) + state.current_window_count;
    if count >= rule.rate {
        return true;
    }
    state.last_count_time = current_time;
    state.current_window_count += 1;
    false
}

// <hide>
fn main() {
    let rule = &Rule {
        time_duration: Duration::from_secs(10),
        rate: 100,
    };
    let origin = Instant::now();
    let mut current_time = origin;
    let state = &mut State {
        current_window_count: 0,
        last_window_count: 0,
        last_count_time: origin,
    };

    for _ in 1..=90 {
        assert_eq!(is_rate_limited(origin, current_time, rule, state), false);
    }
    current_time += Duration::from_secs(12);
    for _ in 1..=28 {
        assert_eq!(is_rate_limited(origin, current_time, rule, state), false);
    }
    assert_eq!(is_rate_limited(origin, current_time, rule, state), true);
    current_time += Duration::from_secs(20);
    for _ in 1..=100 {
        assert_eq!(is_rate_limited(origin, current_time, rule, state), false);
    }
    assert_eq!(is_rate_limited(origin, current_time, rule, state), true);
    current_time += Duration::from_secs(10);
    assert_eq!(is_rate_limited(origin, current_time, rule, state), true);
    current_time += Duration::from_millis(1);
    assert_eq!(is_rate_limited(origin, current_time, rule, state), false);
    
}
// </hide>
`}</code></pre>
    <blockquote>
      <p parentName="blockquote">{`توی پیاده سازی بالا ما زمان آخرین درخواست هم ذخیره می‌کنیم این به این دلیل هست که چک کنیم
که اگه درخواست جدید با آخرین درخواست ۲ تا بازه زمانی فاصله داشته باشن، بیایم و
`}<inlineCode parentName="p">{`current_rate`}</inlineCode>{` و `}<inlineCode parentName="p">{`last_rate`}</inlineCode>{` رو صفر کنیم.`}</p>
    </blockquote>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      