import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3>{`مقایسه و انتخاب یک الگوریتم`}</h3>
    <p>{`حالا که با 4 تا از الگوریتم های
rate limit
آشنا شدیم، بیایم اینارو با هم مقایسه کنیم و در نهایت یکی از اون‌هارو
برای استفاده توی
CDN
مون انتخاب کنیم.`}</p>
    <h4>{`مموری`}</h4>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`fixed window`}</inlineCode>{` ‌برای هر کاربر مموری ثابتی نیاز داره و رشد نمی‌کنه.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`leaky bucket`}</inlineCode>{` مموری ثابتی نداره اما حداکثر مورد نیاز نسبت به قانون مشخص میشه.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`sliding window log`}</inlineCode>{` برای هر کاربر مموری رشد می‌کنه و حداکثری وجود نداره.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`sliding window`}</inlineCode>{` برای هر کاربر مموری ثابتی نیاز داره و رشد نمی‌کنه.`}</li>
    </ul>
    <h4>{`درستی`}</h4>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`fixed window`}</inlineCode>{` این مشکل رو داره که شمارنده ها یک‌دفعه در تایم های مشخص ریست می‌شن.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`leaky bucket`}</inlineCode>{` رفتاری داره که کاربران معمولا انتظار اون رو ندارن.
معمولا وب‌سایت ها می‌خوان که توی یک بازه زمانی یک تعداد فقط درخواست رد بشه نه اینکه
در یک بازه زمانی با یک نرخ ثابت درخواست داشته باشیم. این باعث میشه استفاده کننده به اشتباه بیوفته.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`sliding window log`}</inlineCode>{` درست‌ترین خروجی رو داره.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`sliding window`}</inlineCode>{` خروجی تقریبی اما خیلی خوبی داره.`}</li>
    </ul>
    <p>{`از لحاظ مموری
`}<inlineCode parentName="p">{`fixed window`}</inlineCode>{` و `}<inlineCode parentName="p">{`sliding window`}</inlineCode>{`
بهترین هستند و درست‌ترین بین این دوتا،
`}<inlineCode parentName="p">{`sliding window`}</inlineCode>{` هست.
پس بهتره در ادامه کار، با این الگوریتم جلو بریم.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      