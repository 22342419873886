import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import Mutex from "./mutex";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`چالش های پیاده سازی ratelimiter برای یک CDN - قسمت ۲`}</h1>
    <blockquote>
      <p parentName="blockquote">{`این مطلب ادامه
`}<a parentName="p" {...{
          "href": "/ratelimit-p1"
        }}>{`قسمت ۱`}</a>{` سری
«چالش های پیاده سازی ratelimiter برای CDN»
هست و فرض می‌کنه که شما
قبلا اون رو مطالعه کردید.`}</p>
    </blockquote>
    <p>{`همه وب‌سرور ها این قابلیت رو دارن که تعداد زیادی از درخواست هارو همزمان با هم قبول کنن. با
thread, async
یا حتی با استفاده از
sub-process. اگر یک نفر چند درخواست همزمان ارسال کنه، سرور چطوری
ratelimit
اونو حساب می‌کنه؟ توی این قسمت به این موضوع نگاه می‌کنیم.`}</p>
    <h2>{`اشتراک منابع`}</h2>
    <p>{`تقریبا هر موقع که
concurrency داریم
، باید به این فکر کنیم که
منابع همزمان چطور میتونن دست چند چیز (مثل thread)
باشن. توی مسئله ما، چونکه ممکنه وب سرورمون درخواست های مختلف یک نفر رو با ترد های مختلفی هندل کنه،
و ما باید شمارنده های الگوریتممون بین این ترد ها مشترک باشن، ما هم این مسئله رو داریم و باید یک راه حل براش
در نظر بگیریم.`}</p>
    <Mutex mdxType="Mutex" />
    <ul>
      <li parentName="ul">{`چرا وب سرور بدون همزمانی معنی نمیده. توضیح در مورد
async و thread و حتی ck1000 چالش.`}</li>
      <li parentName="ul">{`مطرح کردن یک وب سرور مولتی ترد.`}</li>
      <li parentName="ul">{`اشتراک گذاری داده با استفاده mutex`}
        <ul parentName="li">
          <li parentName="ul">{`بنچ‌مارک`}</li>
          <li parentName="ul">{`تعمیم و استفاده از futex`}
            <ul parentName="li">
              <li parentName="ul">{`بنچ‌مارک`}</li>
            </ul>
          </li>
        </ul>
      </li>
      <li parentName="ul">{`استفاده از atomic بجای لاک`}</li>
      <li parentName="ul">{`معماری nginx و چالش های چند پراسسی`}</li>
      <li parentName="ul">{`توضیح دقیق در مورد اینکه دسترسی مموری توی چند پراسسی چطور هست.`}</li>
      <li parentName="ul">{`اشتراک مموری با استفاده از shared memory`}
        <ul parentName="li">
          <li parentName="ul">{`آیا استفاده از اتمیک بین پراسس ها سیف هست؟`}</li>
        </ul>
      </li>
    </ul>
    <p>{`قسمت بعدی`}</p>
    <ul>
      <li parentName="ul">{`روش های دیگه اشتراک مموری در چند پراسس`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      