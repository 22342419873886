import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Notes = makeShortcode("Notes");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <hr></hr>
    <h3>{`پانویس`}</h3>
    <Notes mdxType="Notes" />
    <h3>{`منابع`}</h3>
    <div dir="ltr" className="resources">
      <p>{`[`}{`1`}{`]`}{`: `}<a parentName="p" {...{
          "href": "https://konghq.com/blog/how-to-design-a-scalable-rate-limiting-algorithm"
        }}>{`https://konghq.com/blog/how-to-design-a-scalable-rate-limiting-algorithm`}</a></p>
      <p>{`[`}{`2`}{`]`}{`: `}<a parentName="p" {...{
          "href": "https://www.codementor.io/@arpitbhayani/system-design-sliding-window-based-rate-limiter-157x7sburi"
        }}>{`https://www.codementor.io/@arpitbhayani/system-design-sliding-window-based-rate-limiter-157x7sburi`}</a></p>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      