import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3>{`الگوریتم Fixed Window`}</h3>
    <p>{`توی الگوریتم
fixed window
اول یک مبدا شروع زمان در نظر میگیریم که معمولا توی پیاده سازی ها، از
`}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Unix_time"
      }}>{`Unix Time`}</a>{`
استفاده می‌شه. بعد از مشخص کردن مبدا، از مبدا شروع می‌کنیم و هر
t ثانیه
یک علامت می‌زاریم
(که اینجا t همون ۱۰ ثانیه قانونمون هست).
در آخر بازه های بین هر علامت رو بازه زمانی قانونمون در نظر میگیریم و هر نفر اجازه داره فقط
x
(که توی مثال برابر با ۵ است) درخواست در این بازه ها ارسال کنه.`}</p>
    <p><img parentName="p" {...{
        "src": "ratelimit_fixed_window.png",
        "alt": "Fixed Window timeline"
      }}></img></p>
    <p>{`همینطور که توی تصویر ۲ می‌بینید، توی بازه زمانی اول ۵ درخواست اول قبول شدن (فلش های سبز) و ۲ درخواست بعدی بلاک شدن و در بازه زمانی دوم مجدد فقط ۵ درخواست اول بازه  قابل قبول است.`}</p>
    <p>{`یک نمونه پیاده سازی این الگوریتم به زبان Rust:`}</p>
    <blockquote>
      <p parentName="blockquote">{`▼ می‌تونید با زدن روی دکمه
`}<inlineCode parentName="p">{`Rust Playground`}</inlineCode>{`
نسخه کامل و قابل اجراش رو ببینید.`}</p>
    </blockquote>
    <pre><code parentName="pre" {...{
        "className": "language-rust"
      }}>{`// <hide>
use std::collections::HashMap;
use std::time::{Duration, SystemTime};
// </hide>

struct Rule {
    time_duration: Duration,
    rate: usize,
}

/// State[int(now / time_duration)] -> counted requests
type State = HashMap<u128, usize>;

fn is_rate_limited(
    origin: SystemTime,
    current_time: SystemTime,
    rule: &Rule,
    state: &mut State,
) -> bool {
    let current_time_ms = current_time.duration_since(origin).unwrap().as_millis();
    let time_duration_ms = rule.time_duration.as_millis();
    let state_idx = current_time_ms / time_duration_ms;

    let t_state = state.entry(state_idx).or_insert(0);

    *t_state += 1;

    *t_state > rule.rate
}

// <hide>
fn main() {
    let rule = &Rule {
        time_duration: Duration::from_secs(5),
        rate: 5,
    };
    let origin = SystemTime::now();
    let mut current_time = SystemTime::now();
    let state = &mut HashMap::new();

    for _ in 1..=5 {
        assert_eq!(is_rate_limited(origin, current_time, rule, state), false);
    }
    assert_eq!(is_rate_limited(origin, current_time, rule, state), true);
    current_time += Duration::from_secs(5);
    assert_eq!(is_rate_limited(origin, current_time, rule, state), false);
}
// </hide>
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      