import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Interpreter Ignition چیه و توی v8 چطوری کار می‌کنه؟ - قسمت ۳`}</h1>
    <br />
    <p><img parentName="p" {...{
        "src": "v8-ignition-logo.png",
        "alt": "v8-engine-ignition#@{\"maxHeight\":330}"
      }}></img></p>
    <br />
    <br />
    <p>{`توی قسمت قبل در مورد این صحبت کردیم که چطور می‌تونیم کد جاوااسکریپت مون رو به یک فرمت قابل فهم برای کامپایلر تبدیل کنیم. حالا کامپایلر از این فرمت چطور می‌تونه استفاده کنه و یک کد رو اجرا کنه؟`}</p>
    <h3>{`AST Interpreter`}</h3>
    <p>{`یک زبان ساده رو در نظر بگیریم که فقط عبارات ساده ریاضی توش کار می‌کنه. مثلا
‍`}<inlineCode parentName="p">{`1 + 2`}</inlineCode>{`
و یا
`}<inlineCode parentName="p">{`(1 + 1 + 3) * 4`}</inlineCode>{`.
اگه برای مثال دومی بیایم یک AST درست کنیم، همچین چیزی ممکنه بدست بیاد:`}</p>
    <p><img parentName="p" {...{
        "src": "v8-ignition-ast-1.png",
        "alt": "v8-ignition-ast-1.png"
      }}></img></p>
    <p>{`برای اینکه بتونیم یک درخت رو اجرا کنیم، باید از بالاترین Node که اینجا `}<inlineCode parentName="p">{`Expression`}</inlineCode>{` هست شروع کنیم و مقدارش رو حساب کنیم.
بعضی از Node ها مثل همین `}<inlineCode parentName="p">{`Expression`}</inlineCode>{` برای اینکه بتونن اجرا بشن نیاز دارن فرزندانشون اول اجرا بشن.
این کار رو تا جایی انجام میدیم که به اعداد تنها برسیم که دیگه فرزندی ندارن، حالا اعداد حساب شده رو دونه دونه از پایین به بالا می‌بریم تا در نهایت جواب آخر رو داشته باشیم.
حتمالا این توضیح زیاد واضح نبوده پس بیایم مثال بالا رو مرحله به مرحله حل بریم ببینم چی می‌شه.
از Node ریشه که اینجا `}<inlineCode parentName="p">{`Expression`}</inlineCode>{` هست شروع می‌کنیم. این Node یک فرزند داره که می‌تونه یک عدد یا یک عبارت ریاضی باشه.
تو این مثال یک عبارت ریاضی هست به نام `}<inlineCode parentName="p">{`Mul`}</inlineCode>{` که کار ضرب رو انجام می‌ده.
س باید اول ببینیم جواب `}<inlineCode parentName="p">{`Mul`}</inlineCode>{` چی می‌شه.
خود `}<inlineCode parentName="p">{`Mul`}</inlineCode>{` دو فرزند داره که از نوع `}<inlineCode parentName="p">{`Expression`}</inlineCode>{` هستن.
قبل از اینکه جواب `}<inlineCode parentName="p">{`Mul`}</inlineCode>{` رو داشته باشیم، باید جواب فرزند هارو بدست بیاریم و نتیجه رو در هم ضرب کنیم.
نتیجه می‌شه جواب `}<inlineCode parentName="p">{`Mul`}</inlineCode>{`. فرزند سمت چپ اون یک عبارت دیگست که فرزندش یک عملیات ریاضی جمع هست.
مانند `}<inlineCode parentName="p">{`Mul`}</inlineCode>{` دو فرزند داره و برای جوابش نیاز به جواب این دو فرزند داره. به فرزند دست چپ اگر نگاه کنیم یک عدد هست پس جواب فرزند دست چپ می‌شه همون عدد:`}</p>
    <p><img parentName="p" {...{
        "src": "v8-ignition-ast-2.png",
        "alt": "v8-ignition-ast-2.png"
      }}></img></p>
    <p>{`حالا باید فرزند دست راست رو حل کنه که باز خودش یک عمل جمع دیگست که دو فرزند عدد داره. جواب اونو بدست میاریم و بجای خودش می‌زاریم:`}</p>
    <p><img parentName="p" {...{
        "src": "v8-ignition-ast-3.png",
        "alt": "v8-ignition-ast-3.png"
      }}></img></p>
    <p>{`حالا که جواب سمت چپ و راست رو داریم می‌تونیم جواب نهایی عملیات جمع رو هم بدست بیاریم:`}</p>
    <p><img parentName="p" {...{
        "src": "v8-ignition-ast-4.png",
        "alt": "v8-ignition-ast-4.png"
      }}></img></p>
    <p>{`حالا جواب عبارت و سمت چپ `}<inlineCode parentName="p">{`Mul`}</inlineCode>{` هم داریم. فرزند سمت راست اون هم یک عدد هست پس جواب سمت راستش هم موجوده. کافیه این دوتا رو در هم ضرب کنیم تا جواب `}<inlineCode parentName="p">{`Mul`}</inlineCode>{` بدست بیاد:`}</p>
    <p><img parentName="p" {...{
        "src": "v8-ignition-ast-5.png",
        "alt": "v8-ignition-ast-5.png"
      }}></img></p>
    <p>{`و در آخر چون جواب Node ریشه برابر با Mul هست، جواب آخر هم همان می‌شود:`}</p>
    <p><img parentName="p" {...{
        "src": "v8-ignition-ast-6.png",
        "alt": "v8-ignition-ast-6.png"
      }}></img></p>
    <p>{`به این روش اجرا کردن یک کد، AST Interpreter و یا Visitor Pattern می‌گن که میایم هر Node رو تا پایین حل می‌کنیم و جواب رو تا بالا بر می‌گردونیم.
همچین روشی برای زبان ساده بالا ممکنه خوب جواب بده اما برای زبان پیچیده‌ای مثل جاوااسکریپت ممکنه خیلی مناسب نباشه. دلایل ریز زیادی داره اما چنتا از دلایل مهمش این ها هستن:`}</p>
    <ul>
      <li parentName="ul">{`برای پیاده سازی یک AST Interpreter نیاز داریم که از تعداد زیادی recursive فانکشن استفاده کنیم که این برای برنامه های پیچیده می‌تونه خیلی راحت bottleneck بشه.`}</li>
      <li parentName="ul">{`فرمت کد درخت هست یعنی خوندن خود کد از مموری غیرخطیه. این باعث می‌شه زیاد نتونیم از Cache CPU برای نگه داشتن خود کد استفاده کنیم. مثلا اگر یک loop داشته باشیم که محاسبات سنگینی داخلش داره، بیشتر زمان محاسبه صرف این می‌شه که بریم کد رو از مموری بخونیم تا اینکه خود کد رو اجرا کنیم.`}</li>
      <li parentName="ul">{`درسته که AST یک فرمت ساده‌تر از javascript پارس نشده هست، اما خود این فرمت هم نسبت به فرمتی که در ادامه این مقاله بهش می‌پردازیم سطح بالا هستش و برای اجرای بعضی از کد های جاوااسکریپت نیاز هست یکسری تحلیل هارو runtime انجام بدیم. تو مقاله قبل گفتم که برای اینکه بتونیم یک چیزی رو سریع کنیم باید کار کمتری انجام بدیم پس هرچقدر کار پیچیده انجام بدیم، اجرامون هم کندتر می‌شه.`}</li>
    </ul>
    <h3>{`Bytecode Interpreter`}</h3>
    <p>{`احتمالا حتی اگر کد assembly نزدید، نمونه هاشو دیدید. کد زیر یک نسخه ساده شده یک کد assembly هست:`}</p>
    <p><img parentName="p" {...{
        "src": "v8-ignition-asm-1.png",
        "alt": "v8-ignition-asm-1.png"
      }}></img></p>
    <p>{`هر خط کد از دو قسمت OpCode یا کد دستور و پارامتر های اون تشکیل می‌شه. توی مثال بالا از ۳ دستور مختلف استفاده کردیم.
دستور اول یا `}<inlineCode parentName="p">{`MOV`}</inlineCode>{` میاد توی یک مکان حافظه یک مقدار رو منتقل می‌کنه.
پس توی خط اول داریم مقدار 1 رو داخل مکان B می‌ریزیم.
دستور `}<inlineCode parentName="p">{`ADD`}</inlineCode>{` هم میاد پارامتر ورودی که بهش دادیم رو با A جمع می‌کنه و جوابشو داخل A می‌ریزه.
دستور `}<inlineCode parentName="p">{`JMP`}</inlineCode>{` هم مثل goto کار می‌کنه. وقتی بهش رسیدیم برمی‌گردیم به جایی که دستور اشاره کرده.
گر دقت کرده باشید یک چیزی به نام `}<inlineCode parentName="p">{`PC`}</inlineCode>{` داریم که یک اشاره گر به دستوری هست که قرارِ اجرا بشه.
بیام یک قدم بریم جلو:`}</p>
    <p><img parentName="p" {...{
        "src": "v8-ignition-asm-2.png",
        "alt": "v8-ignition-asm-2.png"
      }}></img></p>
    <p>{`دستور اول اجرا شد و مقدار B برابر 1 شد. یک مرحله دیگه کد رو اجرا کنیم:`}</p>
    <p><img parentName="p" {...{
        "src": "v8-ignition-asm-3.png",
        "alt": "v8-ignition-asm-3.png"
      }}></img></p>
    <p>{`مقدار A با B جمع شد و حاصلش رو ریختیم داخل A. الان A مقدار 1 و B هم مقدار 1 رو داره. بریم بعدی:`}</p>
    <p><img parentName="p" {...{
        "src": "v8-ignition-asm-4.png",
        "alt": "v8-ignition-asm-4.png"
      }}></img></p>
    <p>{`مقدار A رو با عدد 2 جمع می‌کنیم. حاصل می‌شه 3 که اونو می‌ریزیم توی A. حالا می‌رسیم به دستور ‍. این دستور تنها کاری که می‌کنه PC (که خودش یک پوینتر هست و پوینتر هم یک عددِ) رو تغییر می‌ده. با این کار مشخص می‌کنه کد بعدی که باید اجرا بشه چیه:`}</p>
    <p><img parentName="p" {...{
        "src": "v8-ignition-asm-5.png",
        "alt": "v8-ignition-asm-5.png"
      }}></img></p>
    <p>{`بر می‌گردیم بالا و اگه دو قدم بریم جلو دوباره به `}<inlineCode parentName="p">{`JMP`}</inlineCode>{` می‌رسیم و A برابر با 6 می‌شه:`}</p>
    <p><img parentName="p" {...{
        "src": "v8-ignition-asm-7.png",
        "alt": "v8-ignition-asm-7.png"
      }}></img></p>
    <p>{`کد بالا تا توی یک چرخه می‌تونه اجرا بشه و مقدار A مدام عوض می‌شه. حالا کد زیر رو نگاه کنید:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`let A = 0;
let B = 0;
B = 1;
while (true) {
  A += B;
  A += 2;
}
`}</code></pre>
    <p>{`کد اسمبلی مثال، مثل کد جاوااسکریپت بالا عمل می‌کنه.
اجرا کردن کد اسمبلی خیلی ساده‌ هست. یکسری دستور هست که یکسری عدد رو تغییر می‌دن و نه بیشتر از این.
گفتیم هرچه ساده‌تر و کار کمتر انجام بدیم، کد سریع‌تری خواهیم داشت. حالا اگه بتونیم کد جاوااسکریپت از AST به اسمبلی تبدیل کنیم،
می‌تونیم خیلی ساده‌تر از AST Interpreting هم اجراش کنیم.
مشکلی که اسمبلی داره اینه که برای هر CPU ای دستورات و معماری متفاوتی داره.
مرورگری مثل کروم که روی دستگاه ها و CPU مختلفی اجرا می‌شه نمی‌تونه صرفا کد جاوااسکریپت رو به یک اسمبلی CPU خاص تبدیل کنه.
برای همین خیلی از زبان ها تصمیم گرفتن بجای اینکه مستقیم تبدیل به اسمبلی کنن کد رو به Bytecode تبدیل کنن.
این Bytecode یک زبانی مثل اسمبلی هست اما با این تفاوت که یک لایه Abstraction روی معماری CPU می‌کشه تا روی معماری مختلف بتونه اجرا بشه.
اگر همچین چیزی داشته باشیم موقع تبدیل کد جاوااسکریپت به این مدل، نیاز نیست به معماری CPU هم فکر کنیم.
زبان های مختلف Bytecode های مختلف خودشون رو دارن.
مثلا C# فرمت IL و جاوا فرمت JVM Bytecode رو داره. جاوا اسکریپت هم Bytecode خودش رو داره که جلوتر فرمت و اینکه چطور اجرا می‌شه رو می‌بینیم.`}</p>
    <h3>{`Ignition`}</h3>
    <p>{`بیایم برای کد جاوااسکریپت زیر ببینیم V8 چه Bytecode هایی براش درست می‌کنه. کد زیر رو داخل یک فایل با نام `}<inlineCode parentName="p">{`test.js`}</inlineCode>{` ذخیره می‌کنیم:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`function test_fn() {
  let A = 0;
  let B = 0;
  B = 1;
  while (true) {
    A += B;
    A += 2;
  }
}
test_fn();
`}</code></pre>
    <p>{`سپس با با استفاده از NodeJs می‌تونیم با اجرای دستور زیر، Bytecode های تابع رو بدست بیاریم.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`$ node --print-bytecode --print-bytecode-filter=test_fn test.js
`}</code></pre>
    <p><img parentName="p" {...{
        "src": "v8-ignition-ignition-1.png",
        "alt": "v8-ignition-ignition-1.png"
      }}></img></p>
    <br />
    <p>{`توی نگاه اول می‌بینیم یکسری تشابهاتی با کد اسمبلی فرضی مون داره. بیایم قسمت هایی که الان مهم نیستن رو پاک کنیم و ببینیم این Bytecode چه معنی ‌ای داره.`}</p>
    <p><img parentName="p" {...{
        "src": "v8-ignition-ignition-2.png",
        "alt": "v8-ignition-ignition-2.png"
      }}></img></p>
    <br />
    <p>{`دستور اول یا `}<inlineCode parentName="p">{`LdaZero`}</inlineCode>{` میاد داخل رجیستر (بخوانید یک مکان حافظه در پردازنده با دسترسی سریع) A (بخوانید رجیستر Accumulator) مقدار 0 رو می‌ریزه.
این رجیستر معمولا برای نگه داشتن نتیجه استفاده می‌شه.
اسم دستور از سه قسمت Ld به معنی `}<inlineCode parentName="p">{`Load`}</inlineCode>{` کردن، `}<inlineCode parentName="p">{`A`}</inlineCode>{` که نام رجیستر هست و `}<inlineCode parentName="p">{`Zero`}</inlineCode>{` که مقدار ثابت صفر هست تشکیل می‌شه.
یعنی بیا مقدار 0 رو بریز توی A. اینکه چرا برای این رجیستر خاص و این مقدار خاص یک دستور جدا مشخص شده به این دلیل هست که این الگو خیلی استفاده می‌شه و کاربردی هست.
ا ساخت یک دستور جدا می‌شه اونو بهینه‌تر هم اجراش کرد چون لازم نیست برای هر بار اجرا چک کنیم مکان حافظه تارگتمون چی هست و می‌دونیم که همیشه رجیستر A خواهد بود.`}</p>
    <p>{`اگر به دستور بعدی نگاه کنیم، ساختار مشابهی داره. `}<inlineCode parentName="p">{`Star0`}</inlineCode>{`. میاد مقدار `}<inlineCode parentName="p">{`A`}</inlineCode>{` رو توی رجیستر `}<inlineCode parentName="p">{`r0`}</inlineCode>{` میریزه که توی دستور قبلی این مقدار صفر بود. دو خط بعدی هم همینکارو با رجیستر `}<inlineCode parentName="p">{`r1`}</inlineCode>{` انجام می‌دن.`}</p>
    <p>{`می‌رسیم به دستور `}<inlineCode parentName="p">{`LdaSmi [1]`}</inlineCode>{`.
این دستور میاد داخل A یک عدد کوچیک می‌ریزه که اینجا عدد یک هست.
دستور بعد هم مقدار `}<inlineCode parentName="p">{`A`}</inlineCode>{` رو داخل `}<inlineCode parentName="p">{`r1`}</inlineCode>{` میریزه.
اگر دقت کرده باشید این چند خط اول دقیقا کار چند خط اول تابع مارو انجام میدن.
اینجا رجیستر `}<inlineCode parentName="p">{`r0`}</inlineCode>{` همون `}<inlineCode parentName="p">{`A`}</inlineCode>{` (متغییر با نام `}<inlineCode parentName="p">{`A`}</inlineCode>{` و نه رجیستر `}<inlineCode parentName="p">{`A`}</inlineCode>{`) هست و رجیستر `}<inlineCode parentName="p">{`r1`}</inlineCode>{` همون `}<inlineCode parentName="p">{`B`}</inlineCode>{`.`}</p>
    <blockquote>
      <p parentName="blockquote">{`انجین تا جای ممکن سعی می‌کنه برای متغییر های اوکال از رجیستر استفاده کنه. اگه زیاد متغییر محلی یا برای تابع پارامتر داشتیم اونارو میاره توی استک.`}</p>
    </blockquote>
    <p><img parentName="p" {...{
        "src": "v8-ignition-ignition-3.png",
        "alt": "v8-ignition-ignition-3.png"
      }}></img></p>
    <br />
    <p>{`دستور `}<inlineCode parentName="p">{`Add`}</inlineCode>{` و `}<inlineCode parentName="p">{`AddSmi`}</inlineCode>{` اینطوری کار می‌کنن که پارامتری که بهشون می‌دیم رو با رجیستر A جمع می‌کنن و نتیجه رو داخل A می‌ریزن. ادامه کدمون پس این معنی رو می‌ده:`}</p>
    <p><img parentName="p" {...{
        "src": "v8-ignition-ignition-4.png",
        "alt": "v8-ignition-ignition-4.png"
      }}></img></p>
    <br />
    <p>{`دستور `}<inlineCode parentName="p">{`JumpLoop`}</inlineCode>{` مثل `}<inlineCode parentName="p">{`JMP`}</inlineCode>{` که گفته بودیم عمل می‌کنه و میاد `}<inlineCode parentName="p">{`PC`}</inlineCode>{` یا `}<inlineCode parentName="p">{`IP`}</inlineCode>{` رو عوض می‌کنه.
با عوض کردن اون به قبل از دستور `}<inlineCode parentName="p">{`A += B`}</inlineCode>{` در اصل ما همون حلقه بی‌نهیات تابعمون رو درست کردیم.
در نهایت می‌دونیم که اگه برای یک تابع `}<inlineCode parentName="p">{`return`}</inlineCode>{` نزاریم بیش‌فرض مقدار `}<inlineCode parentName="p">{`undefined`}</inlineCode>{` برمی‌گردونه، دو خط آخر همینکارو می‌کنن.`}</p>
    <p>{`این مواردی که گفته شد یعنی تبدیل کد جاوا اسکریپت از AST به Bytecode و اجرای اونو موتور Ignition یا Interpreter V8 انجام می‌ده.
این نکته رو در نظر داشته باشید که اگه خودتون دستورات بالا رو اجرا کردید ممکنه خروجی متفاوتی دریافت کنید
چون Bytecode ها توی هر ورژن ممکنه تغییر کنن همچنین یک نسخه جدید V8 می‌تونه استراتژی تولید کد متفاوتی داشته باشه.`}</p>
    <p>{`تو `}<a parentName="p" {...{
        "href": "/v8-jit"
      }}>{`قسمت بعدی`}</a>{` به این میپردازیم که v8 چطوری تصمیم میگیره یک قسمتی از کد رو optimize کنه.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      