import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`چالش های پیاده سازی ratelimiter برای یک CDN - قسمت ۱`}</h1>
    <p><img parentName="p" {...{
        "src": "ratelimit_funnel.png",
        "alt": "Ratelimit funnel#@{\"maxHeight\":300}"
      }}></img></p>
    <br /> 
    <p>{`اگر جایی توی اینترنت با پیام
`}<inlineCode parentName="p">{`TOO_MANY_REQUESTS`}</inlineCode>{`
روبرو شدید، احتمال خیلی زیاد اون وبسایت
rate limit
داشته و تعداد درخواست های شما از حد مجاز تعریف شده برای اون وبسایت بیشتر شده و
rate limit
شدید. به طور کلی
rate limit
روشی برای کنترل جریان هست. توی یک سد اگر میخوایم میزان عبور آب رو کنترل کنیم، میایم جریان آب رو محدود میکنیم به طوری که فقط
x
مقدار آب در بازه زمانی
t
بتونه عبور کنه. توی یک وب سرور این موضوع این طور تعمیم داده میشه که تعداد درخواست های یک کاربر (معمولا کاربران با
IP Address
شون متمایز میشن) در یک بازه زمانی
t
(مثلا ۱۰ ثانیه‌ای) از x تا بیشتر نشه.`}</p>
    <blockquote>
      <p parentName="blockquote">{`نکته: منظور از بازه زمانی توی هر الگوریتم ممکنه متفاوت باشه که جلوتر در موردش صحبت می‌کنیم.`}</p>
    </blockquote>
    <p>{`معمولا مسیر های حساس یا مسیر هایی که پردازش سنگینی نیاز دارند رو
rate limit
می‌کنن. مثلا اگر مسیری مثل
`}<inlineCode parentName="p">{`/auth/sendOTP`}</inlineCode>{`
که کارش ارسال رمز یکبار مصرف به یک شماره هست،
rate limit
نداشته باشه، یک نفر میتونه به تعداد دفعات زیادی این
endpoint
رو صدا بزنه و اعتبار پنل
SMS
تون رو خالی کنه. حالا این
rate limit
کردن چطوری انجام میشه؟`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      