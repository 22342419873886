import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`کنترل کردن USB Switcher با موبایل`}</h1>
    <br />
    <p>{`ستاپ من اینطوریه که یک لپ‌تاپ دارم که اکثر وقتا با اون کار می‌کنم و یک PC هم هست که به عنوان سرور و سیستم ویندوزیم ازش استفاده می‌کنم. این وسط یک میکروفون دارم که با USB وصل می‌تونه بشه به یکی از این سیستم‌ها. مشکل اینجاست که بعضی وقتا میخوام میکروفون رو روی لپ‌تاپ استفاده کنم بعضی وقتا روی PC. یک راهش اینه که USB رو جدا کنم و بین این دوتا سوئیچ کنم. یک راه هم اینه که از USB Switcher استفاده کنم. اومدم چند وقت پیش یکی از اینارو گرفتم:`}</p>
    <br />
    <br />
    <p><img parentName="p" {...{
        "src": "us-1.png",
        "alt": "usb switcher"
      }}></img></p>
    <br />
    <br />
    <p>{`سمت راست و چپ رو با دو تا کابل پرینتر می‌تونم وصل کنم به دوتا سیستم و از بالا هم می‌تونم میکروفون رو وصل کنم. دکمه روش هم وقتی بزنم بین این دوتا سوئیچ می‌شه. چراغ روش هم مشخص می‌کنه الان کدوم یکیش فعاله. اول روی میز گذاشته بودمش ولی چون ظاهر زیاد قشنگی نداشت گذاشتمش گذاشتم زیر میز و با شست پام دکمشو میزنم 😀`}</p>
    <br />
    <p>{`تا همینجا در اصل مشکلمو حل کردم ولی از همون اول که راش انداختم دوست داشتم که بشه با گوشی یا خود لپ‌تاپ هم سوئیچش کرد. USB Switcher ای که همچین کاری رو بکنه و قیمت معقولانه‌ای هم داشته باشه پیدا نکردم. چند روز پیش دست به هویه شدم و گفتم خودم درستش میکنم.`}</p>
    <br />
    <br />
    <h2>{`بازش کنیم ببینیم چطور کار میکنه`}</h2>
    <br />
    <br />
    <p><img parentName="p" {...{
        "src": "us-2.png",
        "alt": "usb switcher inside"
      }}></img></p>
    <br />
    <blockquote>
      <p parentName="blockquote">{`دیسکلیمر!:  تقریبا هیچ بک‌گراندی توی الکترونیک ندارم و هرچی بلدم رو با آزمون و خطا و چارتا کلیپ توی یوتیوب یاد گرفتم. در نتیجه بزرگان الکترونیک به بزرگی خودتون ببخشید اگر چیزی رو اشتباه می‌گم و همیطور خوشحال می‌شم بهم بگید تا بتونم متن رو اصلاح کنم 🙏`}</p>
    </blockquote>
    <br />
    <p>{`اول بازش کردم که ببینم چطوری کار میکنه. اون دکمه‌ای که با شست پا میزدمش اینجاست که در اصل یک Push button ئه و کارش اینه که تا وقتی که نگهش داشتیم اتصال بین دو نقطه رو برقرار می‌کنه. دو نقطه هم مشخص شده رو عکس:`}</p>
    <p><img parentName="p" {...{
        "src": "us-3.png",
        "alt": "usb switcher push button"
      }}></img></p>
    <br />
    <blockquote>
      <p parentName="blockquote">{`ممکنه با خودتون بگید این که ۴ تا پایه داره! در اصل دوتا پایه پایین به هم و دوتا پایه بالا به هم وصلن`}</p>
    </blockquote>
    <p>{`اومدم با یک تیکه سیم این دو نقطه رو بهم وصل کردم که ببینم سوئیچ میشه یا نه که دیدم می‌شه! خیلی هم عالی!`}</p>
    <br />
    <p><img parentName="p" {...{
        "src": "us-4.png",
        "alt": "connect buttons using a usb"
      }}></img></p>
    <br />
    <h2>{`استفاده از یک ترانزیستور`}</h2>
    <br />
    <br />
    <p><img parentName="p" {...{
        "src": "us-5.png",
        "alt": "a transistor"
      }}></img></p>
    <br />
    <p>{`خیلی خلاصه بخوام توضیح بدم اکثر ترانزیستور سه تا پایه دارن:`}</p>
    <p>{`چپ Emitter وسط Base و راست Collector هست. کارشم اینه که وقتی یک جریانی از Base به Emitter برقرار باشه، جریان Collector به Emitter باز میشه.`}</p>
    <br />
    <p><img parentName="p" {...{
        "src": "us-6.png",
        "alt": "a transistor"
      }}></img></p>
    <br />
    <p>{`یا خیلی ساده‌تر اگه Collector رو به نقطه آبی وصل کنیم و Emitter رو به نقطه قرمز، وقتی به Base یک سیگنال وارد کنیم این دوتا به هم وصل میشه. دقیقا چیزی که میخوایم :)`}</p>
    <p>{`دست به هویه شدم و دوتا پایه رو لحیم کردم و یک سیم هم وصل کردم به پایه Base که کارم راحت باشه.`}</p>
    <br />
    <p><img parentName="p" {...{
        "src": "us-7.png",
        "alt": "a transistor"
      }}></img></p>
    <br />
    <h2>{`ارسال سیگنال به پایه Base`}</h2>
    <br />
    <br />
    <p>{`چند سال پیش یک Arduino mega گرفته بودم و استفاده‌ای نمی‌شد ازش. پرفکت! با وصل کردن Arduino به لپ‌تاپ میشه باهاش از طریق tty صحبت کرد. کافیه یک پیام به Arduino بفرستیم و اون بیاد برامون سیگنال رو به Base ترانزیستورمون بفرسته.`}</p>
    <br />
    <p>{` کد روی board:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-c"
      }}>{`void setup()
{
 pinMode(LED_BUILTIN, OUTPUT);
 pinMode(2, OUTPUT);
 Serial.begin(9600);
}

void loop()
{
 String msg = Serial.readString();
 if (msg.length() > 0)
 {
   digitalWrite(2, HIGH);
   delay(100);
   digitalWrite(2, LOW);
   delay(1000);
 }
}
`}</code></pre>
    <br />
    <p>{`کد بالا منتظر می‌مونه یک پیام از پورت Serial بیاد و به مدت ۱۰۰ میلی ثانیه پین ۲ رو HIGH می‌کنه (که تو ستاپ من 5V می‌شه). پین ۲ هم از اون ور وصل شده به Base ترانزیستورمون. اینم عکس اتصالات:`}</p>
    <br />
    <p><img parentName="p" {...{
        "src": "us-8.png",
        "alt": "final"
      }}></img></p>
    <br />
    <p>{`حالا کافیه که یک پیام با tty برای دیوایس بفرستم. توی لینوکس خیلی سادست، اسکریپت زیر رو آماده کردم و اسمشو گذاشتم `}<inlineCode parentName="p">{`usb-switch.sh`}</inlineCode></p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`stty -F /dev/ttyACM0 9600
echo 00000 > /dev/ttyACM0
`}</code></pre>
    <br />
    <p>{`و تموم! چون کل دیوایسام توی یک شبکه هستن و با ssh به صورت لوکال می‌تونن صحبت کنن، خیلی راحت می‌تونم از رو گوشیم دستور زیر رو بزنم و با گوشیم هم بتونم سوئیچ کنم.`}</p>
    <br />
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`ssh dev-laptop usb-switch.sh
`}</code></pre>
    <br />
    <br />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      