import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import FixedWindow from "./fixed_window.mdx";
import SlidingWindowLog from "./sliding_window_log.mdx";
import SlidingWindow from "./sliding_window.mdx";
import LeakyBucket from "./leaky_bucket.mdx";
import Comparison from "./comparison.mdx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Note = makeShortcode("Note");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h2>{`موضوع یک: الگوریتم ها `}<Note mdxType="Note">{`در عمل ممکنه بعضی از الگوریتم ها با یکم تغییر پیاده سازی شده باشن.`}</Note></h2>
    <p>{`الگوریتم های مختلفی برای
rate limit
کردن وجود داره که هر کدومشون خوبی ها و بدی های خودشون رو دارن، اول همشون رو معرفی میکنیم
و میگیم چطوری کار میکنن و از آخر مقایسشون میکنیم، یکی رو انتخاب و ازش در ادامه مطلب استفاده می‌کنیم.`}</p>
    <p>{`نمونه زیر رو در نظر میگیریم تا باهاش بتونیم الگوریتم هارو بفهمیم:`}</p>
    <br id="anchor-ratelimit-example" />
    <p><em parentName="p">{`قانون rate limit ای که در بازه زمانی 10 ثانیه هر نفر که با IP Address اش متمایز میشه، اجازه داشته باشه ۵ درخواست HTTP ارسال کنه.`}</em></p>
    <FixedWindow mdxType="FixedWindow" />
    <LeakyBucket mdxType="LeakyBucket" />
    <SlidingWindowLog mdxType="SlidingWindowLog" />
    <SlidingWindow mdxType="SlidingWindow" />
    <Comparison mdxType="Comparison" />
    <br />
    <br />
    <p>{`در
`}<a parentName="p" {...{
        "href": "/ratelimit-p2"
      }}>{`قسمت بعد`}</a>{`
، به این می‌پردازیم که این الگوریتم در محیط همزمان توی یک سرور چطور عمل می‌کنه.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      