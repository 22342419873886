import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3>{`Mutex`}</h3>
    <p>{`بیایم اول از
`}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Lock_(computer_science)"
      }}>{`Mutex Lock`}</a>{`
استفاده کنیم که راه‌حل ساده‌ای رو به ما می‌ده تا بتونیم اهمیت این موضوع رو درک کنیم.`}</p>
    <p>{`برای سادگی موضوع،‌ در نظر بگیریم یک وب‌سرور داریم که عدد ۱ تا ۱۰۰۰ رو باید با هم جمع کنه
و وقتی داره اینکارو می‌کنه،‌ ترد های دیگه باید منتظر این کار باشن.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-rust"
      }}>{`// <hide>
use parking_lot::Mutex;
use std::sync::mpsc::{self, Sender};
use std::sync::Arc;
use std::thread::JoinHandle;
use std::time::Instant;


#[inline(never)]
#[cold]
// </hide>
fn do_work(lock: &Arc<Mutex<()>>, no_lock: bool) -> usize {
    let _lock_guard;
    if !no_lock {
        _lock_guard = lock.lock();
    }
    let mut result = 0;
    for i in 1..=1000 {
        result += i;
    }
    result
}

pub struct WebServer {
    threads: Vec<(usize, Sender<()>, JoinHandle<usize>)>,
}

impl WebServer {
    pub fn new(num_threads: usize, no_lock: bool) -> Self {
        let lock = Arc::new(Mutex::new(()));
        let threads: Vec<_> = (1..=num_threads)
            .map(|i| {
                let (tx, rx) = mpsc::channel::<()>();
                let lock = Arc::clone(&lock);
                let handle = std::thread::spawn(move || {
                    let lock = lock;
                    let mut total = 0;
                    while let Ok(_) = rx.recv() {
                        total += do_work(&lock, no_lock);
                    }
                    total
                });
                (i, tx, handle)
            })
            .collect();
        WebServer { threads }
    }

    pub fn send_work(&self, num_works: usize) {
        // --snip--
         // <hide>
        self.threads
            .iter()
            .map(|x| x.1.clone())
            .map(|x| {
                std::thread::spawn(move || {
                    for _ in 1..=num_works {
                        x.send(()).unwrap();
                    }
                })
            })
            // It's important to first collect all, then join the threads.
            // We want all the sender threads to start together.
            .collect::<Vec<_>>()
            .into_iter()
            .for_each(|x| x.join().unwrap());
        // </hide>
    }

    pub fn shutdown(mut self) {
        // --snip--
        // <hide>
        while let Some((i, sender, handle)) = self.threads.pop() {
            drop(sender);
            let result = handle.join().unwrap();
            println!("[{}] total = {}", i, result);
        }
        // </hide>
    }

}

// <hide>
const JOBS: usize = 1_000_000;

#[inline(never)]
#[no_mangle]
fn with_lock() {
    let server_with_lock = WebServer::new(16, false);

    let now = Instant::now();
    server_with_lock.send_work(JOBS);
    server_with_lock.shutdown();
    println!("with lock took {:?}", now.elapsed());
}

#[inline(never)]
#[no_mangle]
fn without_lock() {
    let server_without_lock = WebServer::new(16, true);
    let now = Instant::now();
    server_without_lock.send_work(JOBS);
    server_without_lock.shutdown();
    println!("without lock took {:?}", now.elapsed());
}

fn main() {
    with_lock();
    without_lock();
}
// </hide>
`}</code></pre>
    <p>{`بیایم کد رو بازش کنیم.`}</p>
    <p>{`تابع `}<inlineCode parentName="p">{`do_work`}</inlineCode>{` کار جمع کردن و لاک کردن رو برامون انجام میده.
از ورودی یک فلگ با نام
`}<inlineCode parentName="p">{`no_lock`}</inlineCode>{`
هم داریم میگیریم که موقع اجرا در دو حالت با لاک و بدون اون رو اجرا کنیم تا ببینیم
اضافه کردن لاک چقدر توی پرفرمنس تاثیر گذاره.`}</p>
    <p>{`وب سرومون هم مولتی ترد هست
که موقع ایجاد شدن میاد به تعداد خواسته شده، ترد باز میکنه و یک لاک مشترک به همشون میده.`}</p>
    <p>{`یک تابع با نام
`}<inlineCode parentName="p">{`send_work`}</inlineCode>{`
داریم که کارش ارسال همزمان n درخواست با هم هست.`}</p>
    <p>{`در نهایت کار تابع
`}<inlineCode parentName="p">{`shutdown`}</inlineCode>{`
اینه که مطمین بشه همه ترد های سرور کارشون تموم بشه و بعد سرور رو ببنده.`}</p>
    <blockquote>
      <p parentName="blockquote">{`نکته مهم: موقع اجرا کد چون ما از لاک استفاده‌ی واقعی نمی‌کنیم، کامپلر راست توی پروفایل
`}<inlineCode parentName="p">{`release`}</inlineCode>{`
اش میاد اونو optimize می‌کنه.
اگر میخواید کد رو اجرا کنید یا اونو تو حالت
`}<inlineCode parentName="p">{`debug`}</inlineCode>{`
اجرا کنید یا اینکه این آپشن رو به پروفایل
`}<inlineCode parentName="p">{`release`}</inlineCode>{`
اتون توی `}<inlineCode parentName="p">{`Cargo.toml`}</inlineCode>{`
اضافه کنید: `}<inlineCode parentName="p">{`opt-level = 2`}</inlineCode></p>
    </blockquote>
    <p>{`اجرا کنیم و نتیجش رو ببینیم:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ cargo run --release
with lock took 20.474073198s
without lock took 3.526799261s
`}</code></pre>
    <p>{`نتیجه ممکنه توی سیستم شما متفاوت باشه ولی چیزی که مشخص هست اینه که نسخه بدون لاک چندین برابر سریع‌تر هست و تاثیر زیادی
میتونه روی پرفرمنس بزاره.`}</p>
    <p>{`برای اینکه مطمین بشیم که خود عملیات لاک کردن داره روی پرفرمنس تاثیر میزاره میتونیم با استفاده از ابزار
`}<a parentName="p" {...{
        "href": "https://github.com/flamegraph-rs/flamegraph"
      }}>{`cargo-flamegraph`}</a>{`
نمودار
flamegraph
اونو ایجاد کنید.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ cargo flamegraph -c 'perf record -F 997 --call-graph dwarf -g'

[ perf record: Woken up 3009 times to write data ]
Warning:
Processed 218901 events and lost 4 chunks!

Check IO/CPU overload!

[ perf record: Captured and wrote 759.438 MB perf.data (92749 samples) ]
writing flamegraph to "flamegraph.svg

$ open flamegraph.svg
`}</code></pre>
    <p><img parentName="p" {...{
        "src": "ratelimit_mutex_flamegraph.png",
        "alt": "mutex flamegraph"
      }}></img></p>
    <p>{`قسمت آبی، عملیات مربوط به لاک کردن مثل منتظر موندن و آنلاک شدن رو نشون میده که میبینیم حجم زیادی از زمان برنامه رو اینکار گرفته.`}</p>
    <blockquote>
      <p parentName="blockquote">{`برای دیدن نسخه interactive و کامل نمودار بالا،
`}<a parentName="p" {...{
          "href": "/images/ratelimit_mutex_flamegraph.svg"
        }}>{`اینجا`}</a>{`
را کلیک کنید.`}</p>
    </blockquote>
    <p>{`TODO: ارزششو داره بریم به راه حل های دیگه نگاه بندازیم.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      