import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Note = makeShortcode("Note");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3>{`الگوریتم Leaky Bucket`}</h3>
    <p>{`در این الگوریتم درخواست ها قبل از پردازش شدن وارد یک صف می‌شن و منتظر می‌مونن.
این صف یک ظرفیت داره
(rate)
که اگر درخواست جدید اومد و صف پر بود،
درخواست بلاک می‌شه.
از این صف هر `}<inlineCode parentName="p">{`t / rate`}</inlineCode>{` ثانیه `}<Note mdxType="Note">{`یا هر واحد زمانی دیگر`}</Note>{`
یک المان کم میشه تا وقتی که صف خالی بشه.`}</p>
    <p><img parentName="p" {...{
        "src": "ratelimit_leaky_bucket.png",
        "alt": "leaky bucket#@{\"maxWidth\": 350}"
      }}></img></p>
    <p>{`این الگوریتم جدا از اینکه می‌تونه برای
rate limit
کردن استفاده بشه، ‌می‌تونه به عنوان یک کنترل کننده جریان هم استفاده بشه
که درخواست ها با یک نرخ ثابت و کنترل شده انجام بشن.`}</p>
    <p>{`نمونه پیاده سازی این الگوریتم به صورت async در زبان Rust:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-rust"
      }}>{`// <hide>
use std::time::{Duration, Instant};
use tokio::{
    sync::{mpsc, oneshot},
    time::sleep,
};

struct Waiter(oneshot::Receiver<()>);
impl Waiter {
    async fn wait_in_queue(self) {
        self.0.await.unwrap();
    }
}
type RequestResumer = oneshot::Sender<()>;

#[derive(Clone)]
// </hide>
struct Rule {
    time_duration: Duration,
    rate: u32,
}

type Request = (RequestResumer, Waiter);
type Bucket = mpsc::Sender<RequestResumer>;

async fn is_rate_limited(req: Request, bucket_tx: Bucket) -> bool {
    let (req, waiter) = req;
    match bucket_tx.try_send(req) {
        Ok(_) => {
            waiter.wait_in_queue().await;
            false
        }
        // queue is full
        Err(_) => true,
    }
}

async fn leak(rule: Rule, mut bucket_rx: mpsc::Receiver<RequestResumer>) {
    loop {
        if let Some(resumer) = bucket_rx.try_recv().ok() {
            resumer.send(()).unwrap();
        }
        sleep(rule.time_duration / rule.rate).await;
    }
}

// <hide>
#[tokio::main]
async fn main() {
    let rule = Rule {
        rate: 5,
        time_duration: Duration::from_secs(5),
    };
    let (bucket_tx, bucket_rx) = mpsc::channel(rule.rate as _);
    let _ = tokio::spawn(leak(rule, bucket_rx));
    let mut tasks = Vec::new();
    for i in 1..=7 {
        let bucket = bucket_tx.clone();
        let task = tokio::spawn(async move {
            println!("[start] request {}", i);
            let chan = oneshot::channel();
            let req = (chan.0, Waiter(chan.1));
            let start = Instant::now();
            let result = is_rate_limited(req, bucket).await;
            println!(
                "[done] request {} after {:?} = {}",
                i,
                start.elapsed(),
                result
            );
        });
        tasks.push(task);
    }
    for task in tasks {
        task.await.unwrap();
    }
}
// </hide>
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      