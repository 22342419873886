import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`جادوی Context`}</h1>
    <p>{`بعضا وقتا که با API
بعضی از فریمورک‌ها کار می‌کنیم، یکسری API
دارن که به نظر می‌رسه با جادو کار می‌کنن. مثلا چیزی مثل
`}<inlineCode parentName="p">{`useContext`}</inlineCode>{` داخل ری‌اکت. چطوری بهم Context
درست رو برمی‌گردونه؟ یا داخل فریم‌ورک های وب‌سرور Node
چطوری بعضی از فانکشن‌ها بدون اینکه ما `}<inlineCode parentName="p">{`req`}</inlineCode>{` رو بهشون بدیم،
میفهمن باید با کدوم `}<inlineCode parentName="p">{`req`}</inlineCode>{` کار کنن؟`}</p>
    <p>{`بیایم از ری‌اکت که ساده‌ترینشون هست شروع کنیم و همینطوری بریم مثال های پیچیده‌تر رو نگاه کنیم ببینیم چطوری کار می‌کنن. آخر هم یک نتیجه‌گیری کنیم
که کلا این سیستم‌ها از چه مکانیزمی استفاده می‌کنن.`}</p>
    <h2>{`useContext داخل ری‌اکت`}</h2>
    <p>{`احتمالا اگه با ری‌اکت کار کرده باشید، با Context هم آشنا هستید. اگر هم نه یک توضیح خیلی سادش اینه، فرض کنید همچین
چینشی برای کامپوننت هاتون دارید.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<TopComponent>
    <MiddleComponent1>
        <MiddleComponent2>
            <Inner>
        <MiddleComponent2>
    </MiddleComponent1>
</TopComponent>
`}</code></pre>
    <p>{`حالا فرض کنید میخواید یکسری دیتا از بالا به کامپوننت `}<inlineCode parentName="p">{`Inner`}</inlineCode>{` بفرستید بدون که لازم باشه از props استفاده کنید. می‌تونید با `}<inlineCode parentName="p">{`Provider`}</inlineCode>{` اونو ست کنید:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-diff"
      }}>{`+<MyContext.Provider value={{ user: "username" }}>
    <TopComponent>
        <MiddleComponent1>
            <MiddleComponent2>
                <Inner>
            <MiddleComponent2>
        </MiddleComponent1>
    </TopComponent>
+</MyContext.Provider>
`}</code></pre>
    <p>{`و با `}<inlineCode parentName="p">{`useContext()`}</inlineCode>{` اونو پایین تر دریافت کنید:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`function Inner() {
    const { user } = useContext(MyContext);
    console.log(user); // username
    // ...

}
`}</code></pre>
    <p>{`خب پیاده‌سازی همچین چیزی ساده بنظر می‌رسه، میتونیم مقدارشو توی یک متغییر استاتیک ذخیره کنیم و با `}<inlineCode parentName="p">{`useContext`}</inlineCode>{` اونو برش گردونیم.
توی مثال بالا این پیاده‌سازی جواب میده ولی با Context ری‌اکت میشه کارهای پیچیده‌تری کرد. مثلا میشه اونو تو در تو تعریف کرد:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<MyContext.Provider value={{ user: "username1" }}>
    <TopComponent>
        <MiddleComponent1>
            <MyContext.Provider value={{ user: "username2" }}>
                <MiddleComponent2>
                    <Inner>
                <MiddleComponent2>
            </MyContext.Provider>
        </MiddleComponent1>
    </TopComponent>
</MyContext.Provider>
`}</code></pre>
    <p>{`یا حتی توی شاخه‌های مختلف کامپوننت هامون ازش استفاده کرد:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<>
    <MyContext.Provider value={{ user: "username1" }}>
        <Inner>
    </MyContext.Provider>
    <MyContext.Provider value={{ user: "username2" }}>
        <Inner>
    </MyContext.Provider>
</>
`}</code></pre>
    <p>{`که خب این قابلیت رو نمیشه با یک متغییر استاتیک ساده پیاده سازی کرد. یا حداقل نه به این سادگی‌ها!
می‌دونیم که توی مرورگر کد جاوااسکریپتمون سینگل‌ترد اجرا می‌شه. یعنی موازی دو تیکه از کد اجرا نمی‌شن.
اگه توی زمان‌های درستی این متغییر استاتیک یا گلوبال رو با مقدار درستش پر بکنیم، کافیه `}<inlineCode parentName="p">{`useContext`}</inlineCode>{`
همون مقدار متغییر گلوبالمون رو برگردونه.`}</p>
    <p>{`خب مثال اولمون رو در نظر بگیرید:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<MyContext.Provider value={{ user: "username" }}>
    <TopComponent>
        <MiddleComponent1>
            <MiddleComponent2>
                <Inner>
            <MiddleComponent2>
        </MiddleComponent1>
    </TopComponent>
</MyContext.Provider>
`}</code></pre>
    <p>{`رندرر ری‌اکت میاد این درخت رو پیمایش میکنه از بالا تا پایین شروع میکنه:`}</p>
    <ul>
      <li parentName="ul">{`به `}<inlineCode parentName="li">{`MyContext.Provider`}</inlineCode>{` پس مقدار `}<inlineCode parentName="li">{`MyContext.currentValue = { user: "username" }`}</inlineCode>{` میشه`}</li>
      <li parentName="ul">{`می‌رسه به TopComponent و vdom رو می‌سازه.`}</li>
      <li parentName="ul">{`به MiddleComponent1 می‌رسه و اونو هم رندر می‌کنه.`}</li>
      <li parentName="ul">{`به MiddleComponent2 می‌رسه و اونو هم رندر می‌کنه.`}</li>
      <li parentName="ul">{`در نهایت به `}<inlineCode parentName="li">{`Inner`}</inlineCode>{` می‌رسه و `}<inlineCode parentName="li">{`useContext`}</inlineCode>{` مقدار `}<inlineCode parentName="li">{`MyContext.currentValue`}</inlineCode>{` رو برمی‌گردونه که همون `}<inlineCode parentName="li">{`{ user: "username" }`}</inlineCode>{` هست.`}</li>
    </ul>
    <p>{`بریم به یک مثال پیچیده‌تر هم نگاه کنیم:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<MyContext.Provider value={{ user: "username1" }}>
    <MyContext.Provider value={{ user: "username2" }}>
       <Inner>
    </MyContext.Provider>
    <Inner>
</MyContext.Provider>
`}</code></pre>
    <p>{`توی این مثال چی؟ اگه مثل قبل عمل کنیم که به `}<inlineCode parentName="p">{`Inner`}</inlineCode>{` دومی مقدار `}<inlineCode parentName="p">{`username2`}</inlineCode>{` می‌رسه.
اینجاست که باید خود فریم‌ورک بهمون یک هوک بده تا بتونیم زمان خروج رندرد یک کامپوننت رو بفهمیم.
یعنی چی؟ فرض کنید ترتیب اجرا عملیات رندرر بصورت زیر هست:`}</p>
    <pre><code parentName="pre" {...{}}>{`enter(MyContext.Provider)
render()
  enter(MyContext.Provider)
  render()
    enter(Inner)
    render()
    exit(Inner)
  exit(MyContext.Provider)
exit(MyContext.Provider)

`}</code></pre>
    <p>{`حالا ما توی اون متغییر گلوبال اگه بیایم یک درخت درست کنیم که موقع `}<inlineCode parentName="p">{`enter`}</inlineCode>{` برای هر `}<inlineCode parentName="p">{`Context`}</inlineCode>{`
اونو به درخت اضافه کنه و موقع `}<inlineCode parentName="p">{`exit`}</inlineCode>{` آخرین برگ درخت رو حذف کنه،
اگه داخل `}<inlineCode parentName="p">{`useContext`}</inlineCode>{` آخرین برگ این درخت رو بخونیم، همیشه مقدار درستی رو خواهیم داشت!`}</p>
    <h2>{`AsyncLocalStorage داخل Nodejs`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const { AsyncLocalStorage } = require('async_hooks');

const myAsyncLocalStorage = new AsyncLocalStorage();

function middleware(req, res, next) {
  myAsyncLocalStorage.run(new Map(), () => {
    myAsyncLocalStorage.getStore().set('userId', req.headers['x-user-id']);
    next();
  });
}

async function requestHandler(req, res) {
  const store = myAsyncLocalStorage.getStore();
  const userId = store.get('userId');
  res.send(\`Hello user \${userId}\`);
}
`}</code></pre>
    <p>{`خب توی ری‌اکت رندرینگ رو می‌دونیم که در یک لحظه فقط برای یک کامپوننت در حال اجراست، پس می‌شه توی
زمان مناسب اون متغییر گلوبال رو با مقدار درستش پر کرد. ولی برای یک وب‌سرور توی Node
که همزمان ممکنه چندین ری‌کوئست بصورت async
در حال اجرا باشن، رو می‌تونیم چیکار کنیم.
توی کد بالا ما اومدیم برای express که یک وب‌سرور هست، یک پلاگین نوشتیم که میاد یک هدر رو از توی ریکو‌‌ئست می‌خونه و یکجا دخیره میکنه.
و پایین‌تر توی هندلرمون بدون اینکه مستقیم اون به ری‌کوئست داده باشیم می‌تونیم بخونیم.
این `}<inlineCode parentName="p">{`AsyncLocalStorage`}</inlineCode>{` چطوری می‌تونه بفهمه باید مقدار درست رو برگردونه؟`}</p>
    <p>{`اینجا هم جاوا اسکریپت سینگل‌ترد هست ولی محیطمون داخلش همزمانی وجود داره، پس اگه بخوایم از یک
متغییر گلوبال استفاده کنیم ممکنه همزمان پیش بیاد.
همچنین مثل ری‌اکت ما چیزی مثل کامپوننت نداریم که بدونیم کی
enter شد و exit شد تا در زمان مناسب مقدار مناسبی توی متغییر گلوبال ذخیره کنیم.`}</p>
    <p>{`یادمون باشه اینجا همزمانی وجود داره ولی این همزمانی فقط برای کارهای IO است.
یعنی اگه یک کار sync داریم انجام می‌دیم، هیچ کار sync دیگه‌ای انجام نمیشه.`}</p>
    <p>{`همینطور هر ریکو‌‌يست یک task به صورت async یا یک Promise درست می‌کنه.`}</p>
    <p>{`خب حالا اگه به یک طریقی بفهمیم در یک لحظه CPU دست کدوم
Promise هست، میتونیم اینو پیاده سازی کنیم.`}</p>
    <p>{`انجین‌های مختلفی برای جاوااسکریپت هست که یکیش v8 هست.
این انجین به ما یه چیزی به نام PromiseHooks بهمون میده، خیلی خلاصه و ساده
هر وقت یک Promise شروع میکنه به یک کار IO
و ایونت‌لوپ میاد تا وقتی کار IO تموم میشه یک Promise
دیگه‌رو جلو می‌بره، می‌تونیم هوک‌مون رو صدا بزنیم.
پس عملا می‌تونیم بفهمیم که یک promise داره
enter می‌شه و کی اون داره
exit می‌شه.`}</p>
    <p>{`این درخت عملا چیزی مثل call stack هست
ولی برای promise task ها.
که اگه یک تسک داخل یک تسک اجرا بشه اینجا درختشو داریم.`}</p>
    <p>{`حالا توی `}<inlineCode parentName="p">{`run`}</inlineCode>{` کافیه توی
promise فعلی مقداری که میخوایم رو ذخیره کنیم
و هر وقت خواستیم مقدار رو پایین‌تر بخونیم کافیه درخت رو پیمایش کنیم و بریم تا وقتی که به مقداری که می‌خوایم برسیم:`}</p>
    <p><a parentName="p" {...{
        "href": "https://github.com/supabase/edge-runtime/blob/main/crates/node/polyfills/internal/async_hooks.ts"
      }}>{`اینجا`}</a>{` می‌تونید یک پیاده‌سازی کامل از AsyncLocalStorage رو ببینید.`}</p>
    <h2>{`Thread-local storage داخل سیستم عامل`}</h2>
    <p>{`توی AsyncLocalStorage فهمیدیم پیاده‌سازیش از این فرض که
جاوا‌اسکریپت سینگل‌ترد هست استفاده می‌کنه که اون متغییر استاتیک رو آپدیت نگه‌داره. حالا اگه برنام‌مون توی یک زبانی باشه
که بشه مولتی‌ترد کار کرد چی. اینو باید چیکار کنیم؟!`}</p>
    <p>{`به کد C زیر نگاه کنید:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-c"
      }}>{`#include <stdio.h>
#include <pthread.h>

__thread int counter;

void* thread_function(void* arg) {
    counter = (int)(size_t)arg;
    printf("Thread %d: counter = %d\\n", (int)(size_t)arg, counter);
    return NULL;
}

int main() {
    pthread_t threads[2];

    pthread_create(&threads[0], NULL, thread_function, (void*)1);
    pthread_create(&threads[1], NULL, thread_function, (void*)2);

    pthread_join(threads[0], NULL);
    pthread_join(threads[1], NULL);

    return 0;
}
`}</code></pre>
    <p>{`توی مثال بالا با اینکه دوتا ترد دارن یک متغییر گلوبال رو می‌خونن ولی مشکل data-race بوجود نمیاد.
چرا؟ چون این متغییر گلوبال به صورت Thread-local storage تعریف شده.
که خب توی زبان c میشه یک استاتیک با پی‌شوند `}<inlineCode parentName="p">{`__thread`}</inlineCode>{` تبدیل به `}<inlineCode parentName="p">{`TLS`}</inlineCode>{` کرد.`}</p>
    <p>{`پیاده‌سازی های مختلفی از TLS هست که یکیش مال pthread ئه.
چیکار می‌کنه؟`}</p>
    <p>{`TODO:`}</p>
    <p>{`توضیحات کامل‌ترشو می‌تونید توی `}<a parentName="p" {...{
        "href": "https://www.akkadia.org/drepper/tls.pdf"
      }}>{`این`}</a>{` مقاله بخونید.`}</p>
    <h2>{`نتیجه‌گیری`}</h2>
    <p>{`TODO:`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      