import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`V8 چیه و چطور کار می‌کنه؟ - قسمت ۱`}</h1>
    <p><img parentName="p" {...{
        "src": "v8_intro_1.png",
        "alt": "v8-engine#@{\"maxHeight\":300}"
      }}></img></p>
    <br />
    <br />
    <br />
    <blockquote>
      <p parentName="blockquote">{`این قسمت از سری و احتمالا چند قسمت بعدی اون، خلاصه و ساده شده منابع آخر مطلبه که من اومدم بینش
یکسری مطالب دیگه‌رو هم بهش اضافه کردم یا برای یک مطلبی توضیح بیشتر دادم.`}</p>
    </blockquote>
    <p>{`V8 یک موتور برای اجرای جاوااسکریپت و وب‌اسمبلی هست که
در نرم‌افزار های مختلفی مثل
Google chrome
و
nodejs
استفاده می‌شه. این موتور پرفرمنس خیلی بالایی داره و دلیلش خلاقیت و تکنولوژی های جالبی هست که توش استفاده کردن.
هدف این پست اینه که بیاد این پروژه رو معرفی کنه و تا حد خوبی به جزئیات اینکه چطور کار می‌کنه بپردازه.`}</p>
    <h2>{`مرحله اول - تولید Abstract Syntax Tree`}</h2>
    <p>{`برای اینکه بشه یک کد جاوااسکریپت رو اجرا کرد٬ باید اول کد اونو به یک فرمت مخصوصی تبدیل کنیم که موتور
v8 راحت‌تر بتونه باهاش کار کنه.
این فرمت Abstract Syntax Tree یا AST هست که نشون دهنده ساختار اصلی کده و همه داده های اضافه مثل کامنت
و اسپیس از اون حذف شده.`}</p>
    <p>{`مثلا اگر همچین کدی داشته باشیم:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`function salam() {
  const b = "hello";
  console.log(b);
  return 1;
}

var result = salam();
`}</code></pre>
    <p>{`AST اون می‌شه همچین چیزی:`}</p>
    <p><img parentName="p" {...{
        "src": "v8_ast_1.png",
        "alt": "example code AST"
      }}></img></p>
    <p>{`برای این‌که V8 این AST رو تولید کنه٬
، باید این مراحل رو طی کنه:`}</p>
    <p><img parentName="p" {...{
        "src": "v8_building_ast_graph.png",
        "alt": "building ast graph"
      }}></img></p>
    <p>{`بریم ببینیم هر مرحله‌اش داره چیکار می‌کنه.`}</p>
    <blockquote>
      <p parentName="blockquote">{`توی V8 چون تا وقتی AST
نداشته باشیم نمی‌تونیم هیچکاری انجام بدیم٬
V8 میاد AST رو به صورت streaming تولید می‌کنه.
خیلی ساده یعنی مثلا توی کد بالا٬ وقتی تمام کاراکتر های مورد نیازش برای تابع اول رو دریافت کرد
یک Node توی AST ایجاد می‌کنه و اونو بر میگردونه ولی می‌گه هنوز کارش تموم نشده.
اینطوری V8 میتونه مثلا تابع اول رو اجرا کنه با اینکه هنوز بقیه کد رو
parse نکرده.`}</p>
    </blockquote>
    <h3>{`Scanner ـ `}<sup parentName="h3" {...{
        "id": "fnref-1"
      }}><a parentName="sup" {...{
          "href": "#fn-1",
          "className": "footnote-ref"
        }}>{`1`}</a></sup></h3>
    <p>{`کامپایلر هر زبان برنامه‌نویسی برای اینکه بتونه خروجی تولید کنه٬ باید اول بتونه کدهای شمارو بفهمه. اولین قدم فهمیدن٬
بررسی رشته ورودی‌ای هست که بهش دادین. اگه کد زیر رو داشته باشیم:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`if (allow == true) {
  console.log("welcome");
}
`}</code></pre>
    <p>{`کامپایلر باید توالی کاراکتر های ورودی رو بخونه و اونو تبدیل به توکن کنه که کار فهمیدن راحت‌تر بشه:`}</p>
    <p><img parentName="p" {...{
        "src": "v8_scanner_1.png",
        "alt": "scanner example"
      }}></img></p>
    <p>{`فرض کنیم تصویر بالا٬ ساده شده یک scanner برای جاوااسکریپت هست.
کدی رو دادیم بهش و اون توی خروجی بهمون یک توالی از token ها داده.
کار کردن با این توکن ها برای فهم کد به مراتب راحت‌تر از مستقیم کار کردن روی کاراکتر هاست.
مثلا بعدا اگر بخوایم بفهمیم که آیا کاربر اینجا یک شرط نوشته باید دنبال یک الگو از توکن های پشت سر هم باشیم مثلا:`}</p>
    <p><inlineCode parentName="p">{`[..., IF, PARAN_OPEN, IDENT, PARAN_CLOSE, ...]`}</inlineCode>{`
یا
`}<inlineCode parentName="p">{`[..., IF, PARAN_OPEN, KEYWORD, PARAN_CLOSE, ...]`}</inlineCode></p>
    <p>{`که پیدا کردن این الگو به کمک لیست توکن ها خیلی ساده‌تر هست. این قسمت یک نکته مهمی که داره اینه که ممکنه همچین ورودی داشته باشیم:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`if if if true
`}</code></pre>
    <p>{`که توی جاوااسکریپت معنی نمیده ولی
scanner
باز هم توالی توکن
`}<inlineCode parentName="p">{`[IF, IF, IF, TRUE]`}</inlineCode>{`
رو تولید می‌کنه و تایید اعتبار ترکیب درست توکن ها کنار هم توی مرحله بعد یعنی پارسر انجام می‌شه.`}</p>
    <blockquote>
      <p parentName="blockquote">{`نکته: داخل scanner بالا کاراکتر های اضافه مثل فاصله و خط جدید هم حذف شدن ولی لزوما هر کامپایلری این کار رو توی این مرحله انجام نمیده.`}</p>
    </blockquote>
    <p>{`به این تیکه از کامپایلر٬ scanner می‌گن.
اسم های دیگه‌ای هم داره مثل lexer و tokenizer
ولی چون توی v8 از scanner استفاده شده٬ توی این مقاله هم با این اسم می‌ریم جلو.`}</p>
    <p>{`وقتی یک اپلیکیشن دسکتاپ درست می‌کنیم معمولا اونو کامپایل می‌کنیم و خروجی باینری اونو به کاربر می‌دیم. پس اونقدر
سرعت کامپایلر نباید اهمیت داشته باشه. اما توی جاوااسکریپت سیستم کاربر کد رو از ما میگیره و خودش کارهای
interpreting و compile
رو انجام می‌ده.
اینجاست که نوشتن یک کامپایلر سریع خیلی مهم میشه چون هرچی این کامپایلر سریع‌تر باشه٬ سایت کاربر سریع‌تر بالا میاد.`}</p>
    <p>{`به همین علت موتور
v8
نیاز داشته که یکسری کارهای خاص انجام بده که سرعت مراحل مختلف کامپایل بالاتر بره.
مثلا:`}</p>
    <h4>{`Comment`}</h4>
    <p>{`توی پایتون توی ران‌تایم میتونیم کامنت های یک تابع رو بخونیم. در واقع کامنت ها یک رشته هستن:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`def foo():
    """comment"""
    return
print(foo.__doc__) # comment
`}</code></pre>
    <p>{`توی زبان `}<inlineCode parentName="p">{`C#`}</inlineCode>{` میتونیم با انتخاب خودمون از طریق
`}<a parentName="p" {...{
        "href": "https://docs.microsoft.com/en-us/archive/msdn-magazine/2019/october/csharp-accessing-xml-documentation-via-reflection"
      }}>{`Reflection`}</a>{`
کامنت های از نوع Docstring رو بخونیم.`}</p>
    <p>{`همینطور توی زبان rust کامنت های `}<inlineCode parentName="p">{`//`}</inlineCode>{` ایگنور میشن ولی برای کامنت های نوع
Docstring یا `}<inlineCode parentName="p">{`//!`}</inlineCode>{`
توکن درست میشه چون توی سیستم ماکرو اش میتونیم داکیومنت جنریت کنیم.`}</p>
    <p>{`اما توی جاوااسکریپت موتور v8 برای اجرا هیچ نیازی به کامنت ها نداره برای همین بهتره که خیلی سریع از روشون بگذره.`}</p>
    <blockquote>
      <p parentName="blockquote">{`لازم نیست که برای سریع‌تر اجرا شدن سایتتون از این به بعد کامنت نزارید. ابزارهایی که دارید همینکار رو موقع build گرفتن براتون میکنن.
مثلا ابزار `}<a parentName="p" {...{
          "href": "https://github.com/mishoo/UglifyJS"
        }}>{`UglifyJs`}</a>{` که اگر به پروژه هاتون نگاه کنید٬ پیداش می‌کنید.`}</p>
    </blockquote>
    <h4>{`identifier scanning`}</h4>
    <p>{`توی v8 پیچیده‌ترین اما پراستفاده ترین توکن٬
توکن `}<a parentName="p" {...{
        "href": "https://source.chromium.org/chromium/v8/v8.git/+/edf3dab4660ed6273e5d46bd2b0eae9f3210157d:src/token.h;l=147"
      }}><inlineCode parentName="a">{`IDENTIFIER`}</inlineCode></a>{` هست
که برای
`}<a parentName="p" {...{
        "href": "https://tc39.es/ecma262/#sec-identifiers"
      }}>{`Identifier`}</a>{`
تعریف شده داخل داکیومنت فنی
ECMAScript
تولید می‌شه. اسم توابع و متغییر ها٬ Identifier هستن که احتمالا به این موضوع برخوردید که یکسری محدودیت توی انتخاب کاراکتر ها داره:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`ID_START:`}</strong>{` اولین کاراکتر باید `}<inlineCode parentName="li">{`[a-z]`}</inlineCode>{` ٬ `}<inlineCode parentName="li">{`[A-Z]`}</inlineCode>{` ٬ `}<inlineCode parentName="li">{`$`}</inlineCode>{` یا `}<inlineCode parentName="li">{`_`}</inlineCode>{` باشه.`}</li>
      <li parentName="ul"><strong parentName="li">{`ID_CONTINUE:`}</strong>{` در صورت بیشتر از یک حرفی بودن٬ بقیه کاراکتر ها یا باید `}<em parentName="li">{`ID_START`}</em>{` باشن٬ یا عدد.`}</li>
    </ul>
    <p>{`یکی از مهمترین دلایل این محدودیت٬ کاهش ابهام یا ambiguity توی زبان هست.
فرض کنیم کاراکتر `}<inlineCode parentName="p">{`-`}</inlineCode>{` یک کاراکتر مجاز توی identifier باشه. اگه کد زیر رو داشته باشیم:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`var a = 2;
var b = 1;
var a-b = 0;
if (a-b == 0) {
  console.log("A");
} else {
  console.log("B");
}
`}</code></pre>
    <p>{`چنتا برداشت می‌تونیم داشته باشیم:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`برداشت ۱:`}</strong>{` `}<inlineCode parentName="li">{`a-b`}</inlineCode>{` اشاره میکنه به مقدار داخل متغییر تعریف شده با همین نام.`}</li>
      <li parentName="ul"><strong parentName="li">{`برداشت ۲:`}</strong>{` مقدار متغییر با نام `}<inlineCode parentName="li">{`b`}</inlineCode>{` از مقدار متغییر با نام `}<inlineCode parentName="li">{`a`}</inlineCode>{` کم میشه و حاصلشون با صفر مقایسه میشه.`}</li>
    </ul>
    <p>{`اگر بخوایم هرکدوم از اینارو پیاده سازی کنیم
باید برای تولید هر توکن بیایم همه توکن های قبلی رو چک کنیم
و مطمینم بشیم که همچین Identifier ای رو کاربر قبلا تعریف کرده یا نه و نسبت به اون ۱ توکن درست کنیم یا ۳ تا توکن.`}</p>
    <p>{`توی زبان جاوااسکریپت یک تابع بنام `}<inlineCode parentName="p">{`eval`}</inlineCode>{` داریم که اجازه بهمون میده کد های داینامیک اجرا کنیم که باز کارو پیچیده‌تر هم میکنه.`}</p>
    <p>{`برای اینکه بتونیم یک scanner سریع بنویسیم
هرچی کار کمتری داشته باشیم٬ کارمون هم سریع‌تر تموم می‌شه. این ابهامات پیچیدگی و درنتیجه حجم کارمون رو بیشتر میکنن و درنهایت
یک scanner کند خواهیم داشت.
حالا چون این توکن پراستفاده‌ترین توکن هست٬ اضافه کردن کوچیک‌ترین پیچیدگی هم باعث کاهش پرفرمنس میشه.`}</p>
    <p>{`توی جاوااسکریپت چون identifier خیلی محدود هست کار اسکنر هم خیلی ساده میشه
کافیه وقتی به یک `}<em parentName="p">{`ID_START`}</em>{` رسید٬ تا وقتی که کاراکتر های بعدی `}<em parentName="p">{`ID_CONTINUE`}</em>{` هستن اونارو بعنوان
identifier درنظر بگیره و نیاز نیست تحلیل خاصی هم روی تک تک کاراکتر ها انجام بده.`}</p>
    <h4>{`Keywords`}</h4>
    <p>{`keyword ها زیرمجوعه identifier هستن.
برای مثال وقتی scanner به عبارت `}<inlineCode parentName="p">{`if`}</inlineCode>{` می‌رسه٬ این عبارت رو مثل یک
identifier اسکن می‌کنه ولی وقتی میخواد توکن برگردونه٬
باید چک کنه که اگر عبارت یکی از keyword ها بود٬ بجای توکن
`}<inlineCode parentName="p">{`IDENT`}</inlineCode>{`٬ توکن مربوط به keyword رو برگردونه که توی مثال ما می‌شه
توکن `}<a parentName="p" {...{
        "href": "https://source.chromium.org/chromium/v8/v8.git/+/edf3dab4660ed6273e5d46bd2b0eae9f3210157d:src/token.h;l=124"
      }}><inlineCode parentName="a">{`IF`}</inlineCode></a>{`.
چون این چک کردن باید برای هر identifier انجام بشه و قبل‌تر گفتیم که معمول‌ترین توکن٬ `}<inlineCode parentName="p">{`IDENT`}</inlineCode>{` هست٬
این چک کردن رو هرچی بهینه‌تر پیاده سازی کنیم٬‌ می‌تونه تاثیر قابل توجهی روی پرفرمنس بزاره.`}</p>
    <p>{`یک پیاده سازی ساده اون٬ میتونه استفاده از `}<inlineCode parentName="p">{`HashMap`}</inlineCode>{` باشه:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const keywords = {
   'if': Token.IF,
   'function': Token.FUNCTION,
   // ...
};

function getKeyword(ident) {
  return keywords[ident];
}
`}</code></pre>
    <p>{`پیچیدگی زمانی `}<inlineCode parentName="p">{`HashMap`}</inlineCode>{` یا `}<inlineCode parentName="p">{`HashTable`}</inlineCode>{`٬ `}<inlineCode parentName="p">{`O(1)`}</inlineCode>{` در حالت میانگین هست.
در بدترین حالت اما این پیچیدگی زمانی `}<inlineCode parentName="p">{`O(n)`}</inlineCode>{` هست.
بدترین حالت وقتی پیش میاد که ما collision داشته باشیم.
برای فهمیدن این مشکل باید بدونیم یک `}<inlineCode parentName="p">{`HashMap`}</inlineCode>{` چطور کار می‌کنه.`}</p>
    <p>{`فرض کنیم ما توی زبانی که داریم استفاده می‌کنیم فقط `}<inlineCode parentName="p">{`List`}</inlineCode>{` داریم و نیاز داریم یک
‍`}<inlineCode parentName="p">{`HashMap`}</inlineCode>{` پیاده سازی کنیم.
اولین چیزی که نیاز داریم٬ یک `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Hash_function"
      }}>{`hash function`}</a>{` هست.
کار این تابع اینه که از ورودی یک مقداری با هر اندازه ای رو بگیره و یک مقدار با اندازه ثابت بهمون بده.
مثلا یک string بگیره و یک `}<inlineCode parentName="p">{`u32`}</inlineCode>{` بهمون بده. `}<inlineCode parentName="p">{`md5`}</inlineCode>{`, `}<inlineCode parentName="p">{`sha256`}</inlineCode>{` دوتا hash function معروف هستن که
احتمالا باهاشون قبلا کار کردید. اینجا اما تابعی مثل
`}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Cyclic_redundancy_check"
      }}><inlineCode parentName="a">{`crc32`}</inlineCode></a>{`
بیشتر بدرد می‌خوره.`}</p>
    <p>{`اگه بیایم مقادیر زیر رو به این تابع بدیم٬ خروجی های زیر تولید میشه:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`console.log(crc32('if')); // 1362560636
console.log(crc32('function')); // 3400406589
console.log(crc32('while')); // 3235141117
`}</code></pre>
    <blockquote>
      <p parentName="blockquote"><a parentName="p" {...{
          "href": "https://crccalc.com/"
        }}>{`اینجا`}</a>{` می‌تونید آنلاین این تابع رو امتحان کنید.`}</p>
    </blockquote>
    <p>{`حالا ما یک تابعی داریم که هر رشته ای رو تبدیل به یک عدد بین `}<inlineCode parentName="p">{`0`}</inlineCode>{` تا `}<inlineCode parentName="p">{`4294967295`}</inlineCode>{` می‌کنه.
می‌تونیم الآن یک آرایه داشته باشیم که اندازش `}<inlineCode parentName="p">{`4294967296`}</inlineCode>{` باشه و برای پیاده کردن یک المان کافیه هش کلید اون رو بدست بیاریم
و نتیجش میشه index ای که مقدار کلیدمون توش قرار داره:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`class HashTable {
  elements: [];

  function insert(key, value) {
    const idx = crc32(key);
    this.elements[idx] = value;
  }

  function get(key) {
    const idx = crc32(key);
    return this.elements[idx];
  }
}

const table = new HashTable();
table.insert("a", 1);
table.insert("b", 2);
console.log(table.get("a")); // 1
console.log(table.get("b")); // 2
console.log(table.get("c")); // undefined
`}</code></pre>
    <p>{`این پیاده سازی ۲ تا مشکل داره:`}</p>
    <ul>
      <li parentName="ul">{`اگه دوتا مقدار مختلف یک هش یکسان داشته باشن٬ توی یک خونه قرار می‌گیرن و collision داریم.`}</li>
      <li parentName="ul">{`حافظه زیادی می‌گیره چون نیاز داریم برای هر `}<inlineCode parentName="li">{`HashMap`}</inlineCode>{` یک لیست به اندازه `}<inlineCode parentName="li">{`4294967296`}</inlineCode>{` المان داشته باشیم.`}</li>
    </ul>
    <p>{`بیایم اول مشکل اولی رو حل کنیم. بیایم بجای اینکه داخل هر المان یک مقدار ذخیره کنیم٬ یک لیستی از مقادیر ذخیره کنیم و وقتی
به collision رسیدیم٬ مقدارمون رو به اون لیست اضافه کنیم.
وقتی هم میخوایم یک المان رو lookup کنیم اول با هش توی لیست اصلی مستقیم به خونه مورد نظرمون می‌رسیم٬
بعد میایم یک سرچ خطی توی آرایه داخلی می‌زنیم. دلیل اینکه بدترین حالت
`}<inlineCode parentName="p">{`O(n)`}</inlineCode>{` هست هم همینجاست٬ چون مجبوریم اینجا سرچ خطی انجام بدیم.`}</p>
    <p><img parentName="p" {...{
        "src": "v8_hashmap_impl.png",
        "alt": "Hash map impl"
      }}></img></p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`class HashTable {
  elements: [];

  function insert(key, value) {
    const idx = crc32(key);
    let bucket = this.elements[idx];
    if (bucket == null) {
      bucket = [];
      this.elements[idx] = bucket;
    }
    const oldValueIdx = bucket.findIndex(x => x.key == key);
    if (oldValueIdx == -1) {
      bucket.push({ key, value });
    } else {
      bucket[oldValueIdx] = { key, value };
    }
  }

  function get(key) {
    let idx = crc32(key);
    let bucket = this.elements[idx];
    if (bucket == null)
      return null;
    return bucket.find(x => x.key == key);
  }
}

const table = new HashTable();
table.insert("a", 1);
table.insert("b", 2);
console.log(table.get("a")); // 1
console.log(table.get("b")); // 2
console.log(table.get("c")); // null
`}</code></pre>
    <p>{`مشکل دوم اینه که الآن پیاده سازیمون مموری زیادی میگیره. مثلا اگه هش `}<inlineCode parentName="p">{`"a"`}</inlineCode>{` رو حساب کنیم می‌شه `}<inlineCode parentName="p">{`3904355907`}</inlineCode>{`.
یعنی مقدارش باید توی خونه `}<inlineCode parentName="p">{`3904355907`}</inlineCode>{` قرار بگیره و همه خونه قبلی لیستمون خالی و بدون استفاده هستن.
برای رفع این مشکل میتونیم یک محدوده حافظه مشخص کنیم. مثلا بگیم اندازه لیستمون فقط 100 المان باشه
و برای پیاده کردن index درست٬ کافیه مقدار هش رو بر اندازمون تقسیم کنیم و باقی موندش رو بعنوان index استفاده کنیم:`}</p>
    <blockquote>
      <p parentName="blockquote">{`یکی از پر استفاده کارای عمل باقی مونده، همین تبدیل یک رنج بزرگ به یک رنج کوچیک‌تر با اندازه مشخص هست.`}</p>
    </blockquote>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const CAPACITY = 100;
class HashTable {
  elements: [];

  function insert(key, value) {
    const idx = crc32(key) % CAPACITY;
    let bucket = this.elements[idx];
    if (bucket == null) {
      bucket = [];
      this.elements[idx] = bucket;
    }
    const oldValueIdx = bucket.findIndex(x => x.key == key);
    if (oldValueIdx == -1) {
      bucket.push({ key, value });
    } else {
      bucket[oldValueIdx] = { key, value };
    }
  }

  function get(key) {
    let idx = crc32(key) % CAPACITY;
    let bucket = this.elements[idx];
    if (bucket == null)
      return null;
    return bucket.find(x => x.key == key);
  }
}

const table = new HashTable();
table.insert("a", 1);
table.insert("b", 2);
console.log(table.get("a")); // 1
console.log(table.get("b")); // 2
console.log(table.get("c")); // null
`}</code></pre>
    <blockquote>
      <p parentName="blockquote">{`دقت کنید که یک پیاده سازی یک HashMap خیلی پیچیده‌تر از چیزی هست که بالا نوشته شده و متن بالا
فقط ایده اصلی Hash Table رو توضیح می‌ده.`}</p>
    </blockquote>
    <p>{`دیدیم که استفاده از `}<inlineCode parentName="p">{`HashMap`}</inlineCode>{` لزوما همیشه `}<inlineCode parentName="p">{`O(1)`}</inlineCode>{` به ما نمیده و نسبت به شرایط ممکنه بیشتر طول بکشه.
بدلیل همیشه مشخص نبودن پرفرمنس `}<inlineCode parentName="p">{`HashMap`}</inlineCode>{`٬ v8 از چیزی به نام `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Perfect_hash_function"
      }}>{`Perfect Hash Function`}</a>{` استفاده می‌کنه.
PHF ها دسته توابع هش ای هستند که اگر ما
n ورودی مختلف داشته باشیم٬
می‌تونه تابعی به ما بده که خروجیش یک عدد بین
۱ تا n هست.
شبه‌کد زیر رو نگاه کنید:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`let hasher = new PerfectHash(["a", "b", "c"]);
console.log(hasher.hash("a")) // 0
console.log(hasher.hash("b")) // 1
console.log(hasher.hash("c")) // 2
console.log(hasher.hash("e")) // error
`}</code></pre>
    <p>{`حالا چون ما تمام keyword هامون رو می‌دونیم٬ می‌تونیم از
این روش استفاده کنیم و یک hash function مخصوص خودمون رو داشته باشیم.
حالا اگر این تابع هش رو توی پیاده سازی hash table مون دخیل کنیم٬‌
می‌تونیم یک lookup با هزینه زمانی بدترین حالت `}<inlineCode parentName="p">{`O(1)`}</inlineCode>{` داشته باشیم٬ چون
هیچ‌وقت collision نخواهیم داشت.`}</p>
    <blockquote>
      <p parentName="blockquote">{`لیست همه keyword های جاوااسکریپت رو می‌تونید `}<a parentName="p" {...{
          "href": "https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Lexical_grammar#keywords"
        }}>{`اینجا`}</a>{` ببینید.`}</p>
    </blockquote>
    <h4>{`Whitespace`}</h4>
    <p>{`برای کاراکتر فاصله٬ خط جدید٬ تب و کامنت‌ها٬
یک توکن `}<a parentName="p" {...{
        "href": "https://source.chromium.org/chromium/v8/v8.git/+/edf3dab4660ed6273e5d46bd2b0eae9f3210157d:src/token.h;l=166"
      }}><inlineCode parentName="a">{`WHITESPACE`}</inlineCode></a>{` توی v8 تولید می‌شه.
اکثر پارسر ها میان کلا این کاراکتر هارو درنظر نمی‌گیرن و هیچ توکنی هم براشون درست نمی‌کنن ولی
v8 نیاز داره براشون توکن تولید کنه.
یکی از اصلی‌ترین دلایلش٬
`}<a parentName="p" {...{
        "href": "https://tc39.es/ecma262/#sec-automatic-semicolon-insertion"
      }}>{`Automatic Semicolon Insertion`}</a>{`
هست که توی داکیومنت فنی
ECMAScript
اومده. توی جاوااسکریپت اجازه داریم که توی کدمون `}<inlineCode parentName="p">{`;‍`}</inlineCode>{` رو حذف کنیم که این قابلیت یکسری پیچیدگی ها برامون اضافه می‌کنه. مثال زیر رو نگاه کنید:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`return
a + b
`}</code></pre>
    <p>{`این کد به دو صورت میتونه تحلیل بشه:`}</p>
    <p><strong parentName="p">{`حالت اول`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`return a + b;
`}</code></pre>
    <p><strong parentName="p">{`حالت دوم`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`return;
a + b;
`}</code></pre>
    <p>{`که یکیشون مقدار برمی‌گردونه و اون یکی نمیکنه.
v8 حالت دوم رو در نظر میگیره حتی اگر ممکنه اشتباه باشه چون به کد اصلی که کاربر نوشته نزدیک‌تره و برای اینکه بتونه اینکارو بکنه
باید بدونه که بعد `}<inlineCode parentName="p">{`return`}</inlineCode>{` خط جدید درست شده و برای همین هم به توکن `}<inlineCode parentName="p">{`WHITESPACE`}</inlineCode>{` نیاز داره.`}</p>
    <p>{`یک مثال دیگه:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`a = b
++c
// after automatic semicolon insertion:
a = b;
++c;
`}</code></pre>
    <p><strong parentName="p">{`:نکته مهم`}</strong>{` تو بعضی شرایط اینکارو رو انجام نمی‌ده
(بخاطر backward compatibility):`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`a = b + c
(d + e).print();
// same as
a = b + c(d + e).print();

a = c
[0].toString();
// same as
a = c[0].toString();
`}</code></pre>
    <blockquote>
      <p parentName="blockquote">{`درست هندل کردن
whitespace ها توی اسکنر/پارسر ای که می‌نویسی بطوری که کد رو اسپاگتی نکنه٬
یکی از چالش های پارسر نوشتن هست.`}</p>
    </blockquote>
    <h3>{`Parser ـ `}<sup parentName="h3" {...{
        "id": "fnref-2"
      }}><a parentName="sup" {...{
          "href": "#fn-2",
          "className": "footnote-ref"
        }}>{`2`}</a></sup></h3>
    <p>{`پارس کردن مرحله‌ای است که سورس کد شما تبدیل به یک فرمت میانی قابل استفاده توسط کامپایلر می‌شه. چون پارس کردن در مسیر اصلی پردازش شدن یک وبسایت انجام می‌شه و روی لود شدن اولیه اون تاثیر مستقیم داره، خوبه که هیچ کار اضافه‌ای انجام ندیم.
همه تابع های کد شما برای استارتاپ و اجرای اولیه سایت شما لازم نیستن. مثلا اگه از فریمورک react استفاده می‌کنید٬
کامپوننت های شما فانکشن اند و لزوما توی یک صفحه همه کامپوننت ها دیده نمی‌شن.`}</p>
    <p>{`اگه بخوایم همه کد رو همون اول پارس و کامپایل کنیم (کامپایل اینجا یعنی تبدیل
ast به bytecode موتور ignition v8 هست)٬
منابع سیستم رو به خوبی استفاده نکردیم.`}</p>
    <blockquote>
      <p parentName="blockquote">{`توی v8 یک چیزی هست به نام bytecode flushing که میاد تیکه کد هایی که خیلی وقته استفاده نشدن یا کلا دیگه
امکان استفاده شدنشون وجود نداره رو از مموری خارج می‌کنه. میشه روی این فیچر حساب باز کرد که کدهای
استفاده نشده مموری مصرف نکنن اما این نکته رو باید در نظر داشته باشیم که اگه ما همون اول بیایم همه کد رو
ببریم توی رم ۱-لود اولیه سایت رو کند کردیم ۲-تا وقتی که سایکل bytecode flushing انجام نشه٬ مموری رو اشغال کردیم
و اگه سیستم رمش توی این لحظه پر باشه٬ باز سایت خیلی کندتر و حس سنگینی زیادی باز میشه. در نهایت ۳- خود این فرایند
نیاز به پردازش داره و ممکنه زمان‌بر باشه.`}</p>
    </blockquote>
    <p>{`مرورگرها برای بهتر کردن این مسئله٬ کد هارو به صورت lazy پارس می‌کنن و ast ای تولید نمی‌کنن.
v8 علاوه بر این میاد میان کد رو pre-parse هم می‌کنه. `}</p>
    <br />
    <br />
    <p>{`توی `}<a parentName="p" {...{
        "href": "/v8-parser"
      }}>{`قسمت بعدی`}</a>{` با جزئیات بیشتر به نحوه کار کردن parser و pre-parser می‌پردازیم.`}</p>

    <div {...{
      "className": "footnotes"
    }}>
      <hr parentName="div"></hr>
      <ol parentName="div">
        <li parentName="ol" {...{
          "id": "fn-1"
        }}><a parentName="li" {...{
            "href": "https://v8.dev/blog/scanner"
          }}>{`https://v8.dev/blog/scanner`}</a><a parentName="li" {...{
            "href": "#fnref-1",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
        <li parentName="ol" {...{
          "id": "fn-2"
        }}><a parentName="li" {...{
            "href": "https://v8.dev/blog/preparser"
          }}>{`https://v8.dev/blog/preparser`}</a><a parentName="li" {...{
            "href": "#fnref-2",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
      </ol>
    </div>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      